import React, { useState, useRef } from 'react';
import './SearchBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';

const SearchBar = ({ handleSearchChange, initialValue = null, additionSymbol = false, clearOnSubmit = false }) => {
  const [inputValue, setInputValue] = useState(initialValue !== null && initialValue !== "" ? initialValue : "");
  const inputRef = useRef(null); // Create a ref for the input element

  const handleSubmit = (event) => {
    event.preventDefault();
    handleSearchChange(inputValue);
    if (clearOnSubmit) {
      setInputValue("");
    }
    if (inputRef.current) {
      inputRef.current.blur(); // Blur the input element to close the keyboard
    }
  };

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    if (newValue === "") {
      handleSearchChange(newValue);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  return (
    <div className="searchBar">
      <form onSubmit={handleSubmit}>
        <input 
          type="text" 
          value={inputValue} 
          onChange={handleInputChange} 
          onKeyPress={handleKeyPress}
          placeholder="Search job titles, companies, locations, skills..."
          ref={inputRef} // Attach the ref to the input element
        />
        <button type="submit">
          <FontAwesomeIcon icon={additionSymbol ? faPlus : faSearch} />
        </button>
      </form>
    </div>
  );
};

export default SearchBar;
