import Hotjar from '@hotjar/browser';

const siteId = 4986381;
const hotjarVersion = 6;

const initHotjar = () => {
    // Check if the current hostname is not localhost before initializing Hotjar
    if (window.location.hostname !== 'localhost') {
        Hotjar.init(siteId, hotjarVersion);
    }
};

export default initHotjar;
