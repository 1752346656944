import React from 'react';
import './pagesNav.css';

const PagesNav = ({ resultsCount, currentPage, onNav, numberCount=2 }) => {
  const totalPages = Math.ceil(resultsCount / 10);

  const handlePageClick = (pageNumber) => {
    onNav(pageNumber);
  };

  const renderPageNumbers = () => {
    // Generate a list of page numbers to show
    let pageNumbers = [0, currentPage - numberCount, currentPage - 1, currentPage, currentPage + 1, currentPage + numberCount];
  
    // Remove duplicates and numbers outside of the range 1-totalPages
    pageNumbers = [...new Set(pageNumbers)].filter(pageNumber => pageNumber >= 0 && pageNumber < totalPages);
  
    // Render each button
    let renderedPageNumbers = [];
    for (let i = 0; i < pageNumbers.length; i++) {
      if (i === 1 && pageNumbers[i] - pageNumbers[i - 1] > 1) {
        renderedPageNumbers.push(<span key={`ellipsis-${i}`} className="ellipsis">...</span>);
      }
      renderedPageNumbers.push(
        <button key={pageNumbers[i]} className={`page-number ${currentPage === pageNumbers[i] ? 'active' : ''}`} onClick={() => handlePageClick(pageNumbers[i])}>
          {pageNumbers[i] + 1}
        </button>
      );
      if (i === pageNumbers.length - 1 && pageNumbers[i] !== totalPages - 1) {
        renderedPageNumbers.push(<span key={`ellipsis-${i}`} className="ellipsis">...</span>);
      }
    }
  
    return renderedPageNumbers;
  };

  return (
    <div className="pages-nav">
      {renderPageNumbers()}
    </div>
  );
};

export default PagesNav;
