import React, { useState, useEffect } from 'react';
import './FilterSelectionDisplay2.css';
import JobProperty from '../jobProperty';
import {queryJobCountOnly} from '../browseJobs/api_query_jobs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const FilterSelectionDisplay = ({ selectedFilters, onClearFilters }) => {
  const filters_processed = {...selectedFilters};
  delete filters_processed.sort;
  delete filters_processed.filterID;
  filters_processed.search = [`"${filters_processed.search}"`];

  const [jobCount, setJobCount] = useState(0); // State to hold the job count

  // Generate the filter components from selectedFilters, excluding the sort filter
  const filterComponents = Object.entries(filters_processed).flatMap(([key, values]) =>
    values.map(value => {
      // Check if value is a string; if so, use it directly. Otherwise, use value.label.replace(/ \(\d+\)$/, '');
      const displayValue = typeof value === 'string' ? value : value.label.replace(/ \(\d+\)$/, '');
      if (displayValue !== '""') {
        return (
          <JobProperty 
            key={`${key}-${displayValue}`}
            category="filter" 
            value={displayValue}
          />
        );
      }
      return null;
    }).filter(component => component !== null)
  );

  useEffect(() => {
    const fetchJobCount = async () => {
      const count = await queryJobCountOnly(filters_processed);
      setJobCount(count);
    };

    fetchJobCount();
  }, [selectedFilters]);

  return (
    filterComponents.length > 0 && (
      <div className="filter-selection-display2">
        <div className="filter-components2">{filterComponents}</div>
        <div className='line-end'>
          <div className="job-count-display">{jobCount} Jobs</div>
          <div className='subscribed'>SUBSCRIBED</div>
          {Object.values(filters_processed).some(arr => arr.length) && (
            <button onClick={() => onClearFilters(selectedFilters.filterID)} className="clear-filters-btn2">
              <span className="close-icon2"><FontAwesomeIcon icon={faTimes} /></span>
            </button>
          )}
        </div>
      </div>
    )
  )
};

export default FilterSelectionDisplay;
