const validateJobForm = (jobDetails) => {
  console.log(`user: ${jobDetails.user}`);

  const errors = {};

  // Check for company name
  if (!jobDetails.company || jobDetails.company.trim() === '') {
    errors.company = 'Company name is required.';
  }

  // Check for contact methods
  if ((!jobDetails.email || jobDetails.email.trim() === '') &&
      (!jobDetails.whatsapp || jobDetails.whatsapp.trim() === '') &&
      (!jobDetails.link || jobDetails.link.trim() === '')) {
    errors.contact = 'At least one contact method (email, WhatsApp, or job link) is required.';
  }

  // Check for job title
  if (!jobDetails.jobTitle || jobDetails.jobTitle.trim() === '') {
    errors.jobTitle = 'Job title is required.';
  }

  // Check for location
  if (!jobDetails.location || jobDetails.location.trim() === '') {
    errors.location = 'Location is required.';
  }

  // Check for country
  if (!jobDetails.country || jobDetails.country.trim() === '') {
    errors.country = 'Country is required.';
  }

  // Check for seniority
  if (!jobDetails.seniority || jobDetails.seniority.trim() === '') {
    errors.seniority = 'Seniority level is required.';
  }

  // Check for work arrangement
  if (!jobDetails.workArrangement || jobDetails.workArrangement.trim() === '') {
    errors.workArrangement = 'Work arrangement type is required.';
  }

  // Check for primary skills
  if (jobDetails.primarySkills.length < 1) {
    errors.primarySkills = 'At least one primary skill is required.';
  }

  // Check for job description summary
  if (!jobDetails.description_summary || jobDetails.description_summary.trim() === '') {
    errors.description_summary = 'Job description summary is required.';
  }

  // Check for user email
  if (!jobDetails.user || jobDetails.user.trim() === '') {
    errors.user = 'User email is required.';
  }

  // Check for payment details
  if ((jobDetails.minPay && jobDetails.minPay !== '') || 
      (jobDetails.maxPay && jobDetails.maxPay !== '')) {
    if (!jobDetails.currency || jobDetails.currency.trim() === '') {
      errors.currency = 'Currency is required when specifying pay.';
    }
    if (!jobDetails.payInterval || jobDetails.payInterval.trim() === '') {
      errors.payInterval = 'Pay interval is required when specifying pay.';
    }
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors,
  };
};

export default validateJobForm;
