import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faHourglassHalf, faRankingStar, faArrowTrendUp } from '@fortawesome/free-solid-svg-icons';
import './JobBoostUserGuide.css';

const JobBoostUserGuide = () => {
  return (
    <div className="email-user-guide">
        <div className="feature-container-boost">
            <div className="feature-box">
                <FontAwesomeIcon className="userGuide-icon filter-icon" icon={faHourglassHalf} />
                <p>Promote this job over a timeframe that matches <strong>your recruitment objectives</strong></p>
            </div>
            <FontAwesomeIcon className="arrow-icon" icon={faArrowRight} />
            <div className="feature-box">
                <span class="material-symbols-outlined userGuide-icon link-icon">captive_portal</span>
                <p>Replace the existing job links with <strong>any web link, email or phone number</strong> to direct applicants wherever you need them (optional)</p>
            </div>
            <FontAwesomeIcon className="arrow-icon" icon={faArrowRight} />
            <div className="feature-box">
                <FontAwesomeIcon className="userGuide-icon calendar-icon" icon={faRankingStar} />
                <p>The job will <strong>rank at the top</strong> for any relevant job searches AND will be <strong>distributed via email</strong> to our subscribed job-seekers</p>
            </div>
            <FontAwesomeIcon className="arrow-icon" icon={faArrowRight} />
            <div className="feature-box">
                <FontAwesomeIcon className="userGuide-icon paperPlane-icon" icon={faArrowTrendUp} />
                <p>Attract more <strong>top quality applicants</strong></p>
            </div>
        </div>
    </div>
  );
};

export default JobBoostUserGuide;
