import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faCalendarDays, faPaperPlane, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import './EmailUserGuide.css';

const EmailUserGuide = () => {
  return (
    <div className="email-user-guide">
        <div className="feature-container">
            <div className="feature-box">
                <FontAwesomeIcon className="userGuide-icon filter-icon" icon={faFilter} />
                <p>Sign up and add up to 10 filters below</p>
            </div>
            <FontAwesomeIcon className="arrow-icon" icon={faArrowRight} />
            <div className="feature-box">
                <FontAwesomeIcon className="userGuide-icon calendar-icon" icon={faCalendarDays} />
                <p>Decide what days you want to receive updates</p>
            </div>
            <FontAwesomeIcon className="arrow-icon" icon={faArrowRight} />
            <div className="feature-box">
                <FontAwesomeIcon className="userGuide-icon paperPlane-icon" icon={faPaperPlane} />
                <p>Receive the latest jobs matching your filters to your inbox</p>
            </div>
        </div>
    </div>
  );
};

export default EmailUserGuide;
