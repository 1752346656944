import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import TalentForm from './TalentForm.jsx';
import './TalentPool.css';

const TalentPool = () => {
    const { isSignedIn, user } = useUser();

	return (
	  <div>

        <div className="intro-container-analysis">
            <div className="intro-analysis">Quant-Jobs Talent Pool</div>
            <div className="intro-container-analysis-2">
                <div className="intro-analysis-2">
                    <div className="secondary-headers">Apply to join below</div>
                    <div className="secondary-headers"></div>
                </div>
            </div>
        </div>

        <TalentForm userID={user ? user.id : ''}/>

	  </div>
	);
};

export default TalentPool;