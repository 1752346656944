import React, { useState, useEffect } from 'react';
import FilterComponent from './FilterComponent2.jsx';
import FilterSelectionDisplay from './FilterSelectionDisplay2.jsx';
import EmailUserGuide from './EmailUserGuide.jsx';
import { useUser, useClerk } from '@clerk/clerk-react';
import WeekdaySelector from './WeekdaySelector.jsx';
import { upsertFilterSubscriptions, fetchFilterSubscriptions, deactivateFilterSubscription, fetchSubscriptionDays, updateSubscriptionDays } from './filterSubsApi.js';
import './manageFilters.css';

const ManageFilters = ({selectedFiltersProp=null}) => {

    console.log(selectedFiltersProp);

    const [selectedFilters, setSelectedFilters] = useState(
        selectedFiltersProp || {  // Use optional chaining to safely access selectedFilters
            search: "",
            location: [],
            seniority: [],
            workArrangement: [],
            company: [],
            type: [],
            skills: [],
            jobCategory: [],
            marketSide: [],
        }
    );

    const [usersFiltersSets, setUsersFiltersSets] = useState([{
        filterID: null,
        search: "",
        location: [],
        seniority: [],
        workArrangement: [],
        company: [],
        type: [],
        skills: [],
        jobCategory: [],
        marketSide: [],
    }]);

    const [usersSubDays, setUsersSubDays] = useState([{}]);

    const isFiltersEmpty = (filters) => {
        return !filters.search.trim() &&
               filters.location.length === 0 &&
               filters.seniority.length === 0 &&
               filters.workArrangement.length === 0 &&
               filters.company.length === 0 &&
               filters.type.length === 0 &&
               filters.skills.length === 0 &&
               filters.jobCategory.length === 0 &&
               filters.marketSide.length === 0;
    };

    const { isSignedIn, user } = useUser();  // Called at the top level of the component
    const { openSignIn } = useClerk();       // Called at the top level of the component

    const handleFilterSubmit = async () => {
        if (!isSignedIn || !user || !user.id) {
            openSignIn();
            return;
        }

        try {
            await upsertFilterSubscriptions(user.id, selectedFilters);
            fetchAndSetFilters();
            setSelectedFilters({
                search: "",
                location: [],
                seniority: [],
                workArrangement: [],
                company: [],
                type: [],
                skills: [],
                jobCategory: [],
                marketSide: [],
            })
        } catch (error) {
            console.error("Failed to update filters:", error);
            alert("Failed to update filters. Please try again.");
        }
    };

    const fetchAndSetFilters = async () => {
        if (!isSignedIn || !user || !user.id) {
            console.log("User not signed in");
            return;
        }
        try {
            const fetchedFilters = await fetchFilterSubscriptions(user.id);
            setUsersFiltersSets(fetchedFilters);
            const subDays = await fetchSubscriptionDays(user.id);
            setUsersSubDays(subDays);

        } catch (error) {
            console.error("Failed to fetch filters:", error);
        }
    };

    useEffect(() => {
        fetchAndSetFilters();
    }, [isSignedIn, user]);


    const handleDeactivateFilter = async (filterID) => {
        try {
            const response = await deactivateFilterSubscription(filterID);
            // Optionally, refresh the list of filters or remove the deactivated filter from the UI
            const updatedFilters = usersFiltersSets.filter(filterSet => filterSet.filterID !== filterID);
            setUsersFiltersSets(updatedFilters);
        } catch (error) {
            console.error("Error deactivating filter:", error);
            alert("Failed to deactivate filter. Please try again.");
        }
    };

    return (
        <div className="filters-page">

            <div className="intro-container-analysis">
                <div className="intro-analysis">Filtered Jobs to Your Inbox</div>
                <div className="intro-container-analysis-2">
                <div className="intro-analysis-2">Set filters below and lets new jobs come to you</div>
                </div>
            </div>
            <div className="intro-container">
                <div className="intro">Receive Filtered Jobs Direct To Your Inbox</div>
            </div>

            <EmailUserGuide/>

            <div className="addFilterContainer">
                <div className="mf-sub-title">Add Filters Here</div>
                <FilterComponent selectedFilters={selectedFilters} onFilterChange={setSelectedFilters}/>
                <button className='submit-filter' onClick={handleFilterSubmit} disabled={isFiltersEmpty(selectedFilters)}>
                    Add Filter
                </button>
            </div>

            <div className='filter-sets'>
                <div className="mf-sub-title">Your Filters:</div>
                {usersFiltersSets.length > 0 && usersFiltersSets[0].filterID !== null ? (
                    <div className="filters-display">
                        {usersFiltersSets.map((filterSet, index) => (
                            <div key={index} className={`filter-set-container ${index % 2 === 0 ? 'filter-set-container-white' : 'filter-set-container-grey'}`}>
                                <FilterSelectionDisplay selectedFilters={filterSet} onClearFilters={handleDeactivateFilter}/>
                            </div>
                        ))}
                        <WeekdaySelector selectedDays={usersSubDays} onUpdate={(days) => updateSubscriptionDays(user.id, days)}/>
                    </div>
                ) : (
                    <div className="filters-display">
                        <div className="filter-set-container-empty">
                            <p style={{ fontSize: '2em' }}>{`\u{1F632}`}</p>
                            <p>Nothing Here!</p>
                            <p>Add Your First Filter Above</p>
                        </div>
                    </div>
                )}
            </div>

        </div>
    );
};

export default ManageFilters;
