import React from 'react';
import './FilterSelectionDisplay.css';
import JobProperty from '../../jobProperty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const FilterSelectionDisplay = ({ selectedFilters, onClearFilters, onRemoveFilter, includeSearch = false }) => {
  // Remove the sort field from selectedFilters
  const filtersWithoutSortAndSearch = {...selectedFilters};
  delete filtersWithoutSortAndSearch.sort;
  if (!includeSearch) {
    delete filtersWithoutSortAndSearch.search;
  } else {
    filtersWithoutSortAndSearch.search = [`"${selectedFilters.search}"`];
  }


  const handleFilterClick = (key, value) => {
    console.log(value)
    onRemoveFilter(key, value);
  };

  // Generate the filter components from selectedFilters, excluding the sort filter
  const filterComponents = Object.entries(filtersWithoutSortAndSearch).flatMap(([key, values]) =>
    values.map(value => {
      // Check if value is a string; if so, use it directly. Otherwise, use value.label
      const displayValue = typeof value === 'string' ? value : value.label.replace(/ \(\d+\)$/, '');
      if (displayValue !== '""') {
        return (
          <JobProperty 
            key={`${key}-${displayValue}`}
            category="filter" 
            value={displayValue}
            showCloseIcon={true}
            onClick={() => handleFilterClick(key, value)}
          />
        );
      }
      return null;
    }).filter(component => component !== null)
  );


  return (
    filterComponents.length > 0 && (
      <div className="filter-selection-display">
        <div className="filter-components">{filterComponents}</div>
        {Object.values(filtersWithoutSortAndSearch).some(arr => arr.length) && (
          <button onClick={onClearFilters} className="clear-filters-btn">
            <span className="close-icon"><FontAwesomeIcon icon={faTimes} /></span>
          </button>
        )}
      </div>
    )
  );
};

export default FilterSelectionDisplay;
