import React, { useEffect, useState } from 'react';
import { Route, Link, Routes } from 'react-router-dom';

import './App.css';
import AnalysisPage from './analysisPage/analysisPage.jsx';
import About from './about/About.jsx';
import ManageFilters from './filterSubscribe/manageFilters.jsx';
import MasonryPage from './masonry/Masonry.jsx';
import ResourceList from './resources/ResourceList.jsx';
import MobileKdbJobs from './mobile/mobileJobBoard';
import JobPage from './jobPage/JobPage';
import PrivacyPolicy from './policies/PrivacyPolicy.jsx';
import CookieConsentComponent from './policies/CookieConsentComponent.jsx';
import { getLocalCurrency } from './currencySelector';
import TopBar from './TopBar_mui';
import EmployersPortal from './employersPortal/EmployersPortal';
import BrowseJobsPage from './browseJobs/browseJobsPage.jsx';
import './utils/pageTracking.jsx';
import initHotjar from './utils/initHotjar';
import {queryJobs} from './browseJobs/api_query_jobs.js';
import {get_distinct_values} from './analysisPage/apiAnalysisPage.jsx';
import PricingPage from './stripe/PricingPage.jsx';
import ConfirmationPage from './stripe/ConfirmationPage.jsx';
import { getActiveUsersCount } from './api';
import TalentPool from './talentPool/TalentPool.jsx';

function App() {
  
  const [currency, setCurrency] = useState(getLocalCurrency());

  //browse page
  const [currentPage, setCurrentPage] = useState(0);
  const [jobs, setJobs] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [fullLatLong, setFullLatLong] = useState([]);
  const [isJobsReelLoading, setIsJobsReelLoading] = useState(true);
  const [activeUsersCount, setActiveUsersCount] = useState();

  //filters for mobile job search page
  const [selectedFilters, setSelectedFilters] = useState({
    search: "",
    location: [],
    seniority: [],
    workArrangement: [],
    company: [],
    type: [],
    skills: [],
    jobCategory: [],
    marketSide: [],
    sort: 'relevant'
  });

  useEffect(() => {
    setCurrentPage(0);
  }, [selectedFilters]);

  useEffect(() => {
    const fetchJobs = async () => {
      setIsJobsReelLoading(true); // Set loading to true before API call
      const populatedFilters = Object.entries(selectedFilters).reduce((acc, [key, value]) => {
        if (Array.isArray(value) ? value.length > 0 : value) {
          acc[key] = value;
        }
        return acc;
      }, {});
  
      const fetchedData = await queryJobs(populatedFilters, currency, currentPage);
      setJobs(fetchedData.jobs);
      setTotalResults(fetchedData.total);
      setFullLatLong(fetchedData.latlong);
      setIsJobsReelLoading(false); // Set loading to false after API call
    };
  
    fetchJobs();
  }, [selectedFilters, currency, currentPage]);

  useEffect(() => {
    const fetchActiveUsersCount = async () => {
      const data = await getActiveUsersCount();
      if (data && data.active_users_count) {
        setActiveUsersCount(data.active_users_count);
      }
    };

    fetchActiveUsersCount();
  }, []);

  //analysis page

  const [uniqueFilters, setUniqueFilters] = useState({
    countries: [],
    seniorities: [],
    employmentTypes: [],
    workArrangements: []
  });

  useEffect(() => {
    const fetchDistinctValues = async () => {
      const distinctValues = await get_distinct_values(['country','employmentType','workArrangement']);
      setUniqueFilters({
        countries: ['All Countries', ...distinctValues.country],
        employmentTypes: ['All Employment Types', ...distinctValues.employmentType],
        workArrangements: ['All Work Arrangements', ...distinctValues.workArrangement]
      });
    };
    fetchDistinctValues();
  }, []); 

  //can delete
  const [jobs_active, setJobsActive] = useState([]);
  const [jobs_analysis, setJobsAnalysis] = useState([]);

  //hiding site
  const [showMessage, setShowMessage] = useState(false);
  const toggleDisplay = () => {
    setShowMessage(!showMessage);
  };

  //get job data
  useEffect(() => {
    initHotjar();
  }, []);

  const isMobileDevice = () => {
    // Check if the device is one of the common mobile devices
    const isMobileUA = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    
    // Check if the screen width is less than a typical mobile width, e.g., 768 pixels
    const isLargeWidth = window.innerWidth > 1000;
  
    return isMobileUA && !isLargeWidth;
  };

  return (
    <>
      <div className="App">
        
        {showMessage ? (
          <div className="site-down-message">
            <h1>Quant-Jobs</h1>
            <p>The site is down for planned maintainance.</p>
            <p>Please try again shortly.</p>
            <p>Thanks for visiting!</p>
            <button className="access-button" onClick={toggleDisplay}>Admin</button>
          </div>
        ) : (
          <>
            <TopBar setCurrency={setCurrency} />

            <Routes>
              <Route
                path="/"
                element={isMobileDevice() ? 
                  <MobileKdbJobs jobs={jobs} totalResults={totalResults} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} currentPage={currentPage} setCurrentPage={setCurrentPage} isLoading={isJobsReelLoading}/> : 
                  <BrowseJobsPage jobs={jobs} totalResults={totalResults} currency={currency} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} currentPage={currentPage} setCurrentPage={setCurrentPage} filteredJobsLocations={fullLatLong} isLoading={isJobsReelLoading} activeUsersCount={activeUsersCount} />
                }
              />
              <Route path="/analysis" element={<AnalysisPage currency={currency} uniqueFilters={uniqueFilters}/>} />
              <Route path="/about" element={<About />} />
              <Route path="/feed" element={<MasonryPage />} />
              <Route path="/resources" element={<ResourceList />} />
              <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
              <Route path="/job/:jobID" element={<JobPage currency={currency}/>} />
              <Route path="/employers/*" element={<EmployersPortal />} />
              <Route path="/myfilters" element={<ManageFilters selectedFiltersProp={selectedFilters}/>} />
              <Route path="/checkout/:jobID" element={<PricingPage />} />
              <Route path="/confirmation" element={<ConfirmationPage />} />
              <Route path="/talentPool" element={<TalentPool />} />
            </Routes>
          </>
        )}

        <div className="info-bar">
          <Link to="/about">About</Link>
          {/*<Link to="/resources">Resources</Link>*/}
          <Link to="/PrivacyPolicy">Privacy Policy</Link>
        </div>

      </div>

      <CookieConsentComponent />
    </>

  );

}

export default App;
