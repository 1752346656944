import React, { useState, useEffect } from 'react';
import JobGrid from './JobGrid';
import AccessDenied from './AccessDenied';
import EmployerJobsIntro from './EmployerJobsIntro';
import { fetchJobsByCompany } from '../api';
import './EmployersPortal.css';

function CompanyJobsPage({ company, uniqueColumnValues, isSignedIn, user, isPrivileged, jobLimit }) {

  const [originalJobs, setOriginalJobs] = useState([]);
  const [additionalJobs, setAdditionalJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Define fetchJobs function to be used within useEffect hooks
  const fetchJobs = async () => {
    if (company) {
      setIsLoading(true); // Start loading
      const jobsByCompany = await fetchJobsByCompany(false, company);
      setOriginalJobs(jobsByCompany);
      const jobsByCompanyAdditional = await fetchJobsByCompany(true, company);
      setAdditionalJobs(jobsByCompanyAdditional);
      setIsLoading(false); // End loading
    }
  };

  const refreshJobs = () => {
    fetchJobs();
  };

  // This useEffect now correctly uses the fetchJobs function
  useEffect(() => {
    if (company) {
      fetchJobs();
    }
  }, [company]); 

  const isValidUserAndCompany = isSignedIn && company && company !== 'n/a' && company !== '';
  if (!isValidUserAndCompany) {
    return <AccessDenied isSignedIn={isSignedIn}/>;
  }

  return (
    <div>
      {isLoading && (
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      )}
      <EmployerJobsIntro company={company} setIsLoading={setIsLoading} jobLimit={jobLimit} />
      {originalJobs.length > 0 && (
        <JobGrid jobs={originalJobs} directListing={false} company={company} uniqueColumnValues={uniqueColumnValues} user={user} refreshJobs={refreshJobs} isPrivileged={isPrivileged} jobLimit={jobLimit} />
      )}
      <JobGrid jobs={additionalJobs} directListing={true} company={company} uniqueColumnValues={uniqueColumnValues} user={user} refreshJobs={refreshJobs} isPrivileged={isPrivileged} jobLimit={jobLimit} />
    </div>
  );

}

export default CompanyJobsPage;