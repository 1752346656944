import React from 'react';
import './About.css';

const About = () => {

    return (
        <div className="about-page" >

            <div className="intro-container">
                <div className="intro">Welcome to Quant-Jobs.com</div>
            </div>

            <div className="about-section">
                <div className="content-wrapper">
                    <div className="content-text">
                      <p>We're a dedicated hub for connecting talented quant professionals with top-tier job opportunities.</p>
                        <p><strong>The mission is simple:</strong></p>
                        <p>to bring transparency to the job market and enhance the quant community!</p>
                      <p>If you have questions, ideas, feedback, or just want to say hi? We'd love to hear from you! Reach out at <a href="mailto:will@leftjoindata.com">will@leftjoindata.com</a>.</p>
                    </div>
                </div>
            </div>

            {/* <div className="faq-section">
                <div className="content-wrapper">
                    <div className="section-title">FAQs</div>
                    <div className="faq-content">
                        <div className="faq-question-container">
                            <div className="faq-question">How does a job qualify for the board?</div>
                            <div className="faq-answer">
                                To be eligable a job must include kdb+/q skills as either a necessity or a "nice to have".
                            </div>
                        </div>
                        <div className="faq-question-container">
                            <div className="faq-question">What are primary and secondary skills?</div>
                            <div className="faq-answer">
                                A primary skill is a "must have" skill. A secondary skill is a "nice to have" skill.
                            </div>
                        </div>
                        <div className="faq-question-container">
                            <div className="faq-question">How can I add or amend a kdb+ job post?</div>
                            <div className="faq-answer">
                                We are working on a portal for employers and recruiters to make additions and changes. In the meantime you can email <a href="mailto:will@leftjoindata.com">will@leftjoindata.com</a>.
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="details-section">
                <div className="content-wrapper">
                    <div className="content-text">
                      <p>Left Join LIMITED, LLC</p>
                      <p>Company Number: 15431742</p>
                    </div>
                </div>
            </div>

        </div>

    );
};

export default About;
