import React, { useState, useEffect } from 'react';
import FilterDropdown from '../browseJobs/map/FilterDropdown';
import FilterSelectionDisplay from '../browseJobs/map/FilterSelectionDisplay'; // Import the new component
import SearchBar from '../browseJobs/map/SearchBar';
import { getDropDownList } from '../browseJobs/api_query_jobs';
import './FilterComponent2.css';

const FilterComponent = ({ selectedFilters, onFilterChange }) => {

const [locationOptions, setLocationOptions] = useState([]);
const [seniorityOptions, setSeniorityOptions] = useState([]);
const [workArrangementOptions, setWorkArrangementOptions] = useState([]);
const [typeOptions, setTypeOptions] = useState([]);
const [companyOptions, setCompanyOptions] = useState([]);
const [skillsOptions, setSkillsOptions] = useState([]);
const [marketSideOptions, setMarketSideOptions] = useState([]);
const [jobCategoryOptions, setJobCategoryOptions] = useState([]);

useEffect(() => {
  const fetchDropDownList = async () => {
    setLocationOptions(await getDropDownList(['country','location']));
    setSeniorityOptions(await getDropDownList(['seniority']));
    setWorkArrangementOptions(await getDropDownList(['workArrangement']));
    setTypeOptions(await getDropDownList(['employmentType']));
    setCompanyOptions(await getDropDownList(['company']));
    setSkillsOptions(await getDropDownList(['primarySkills', 'secondarySkills']))
    setMarketSideOptions(['BuySide', 'SellSide'])
    setJobCategoryOptions(['Quant Analyst', 'Quant Risk', 'Quant Researcher', 'Quant Developer', 'Quant Trader'])
  };
  fetchDropDownList();
}, []);

const resetFilters = () => {
    const resetState = {
      search: "", // Keep the search filter as it is
      location: [],
      seniority: [],
      workArrangement: [],
      type: [],
      company: [],
      skills: [],
      jobCategory: [],
      marketSide: [],
      sort: selectedFilters.sort
      // Do not reset 'sort' here if you're managing it separately or want it to persist
    };
    onFilterChange({ ...resetState});
  };

  const handleSearchChange = (searchString) => {
    const updatedFilters = { ...selectedFilters, search: searchString };
    onFilterChange(updatedFilters);
  };

  // Handler to append a new selection
  const handleSelectionChange = (filterType, selectedOption) => {
    if (selectedOption && !selectedFilters[filterType].find(option => option.value === selectedOption.value)) {
      const newFilters = {
        ...selectedFilters,
        [filterType]: [...selectedFilters[filterType], selectedOption.value],
      };
      onFilterChange(newFilters); // Notify the parent component of the filter change
    }
  };

  const handleRemoveFilter = (filterType, selectedOption) => {
    let newFilters = { ...selectedFilters };

    if (filterType === 'search') {
      newFilters.search = "";
    } else {
      const filteredOptions = selectedFilters[filterType].filter(option => option !== selectedOption);
      newFilters[filterType] = filteredOptions;
    }

    onFilterChange(newFilters);
  };

  return (
    <div className='filter-component'>
      <SearchBar handleSearchChange={handleSearchChange} additionSymbol={true} clearOnSubmit={true}/>
      <div className="filter-container">
        <FilterDropdown label="Location" options={locationOptions} onChange={(selectedOption) => handleSelectionChange('location', selectedOption)} />
        <FilterDropdown label="Seniority" options={seniorityOptions} onChange={(selectedOption) => handleSelectionChange('seniority', selectedOption)} />
        <FilterDropdown label="Work From" options={workArrangementOptions} onChange={(selectedOption) => handleSelectionChange('workArrangement', selectedOption)} />
        <FilterDropdown label="Type" options={typeOptions} onChange={(selectedOption) => handleSelectionChange('type', selectedOption)} />
        <FilterDropdown label="Company" options={companyOptions} onChange={(selectedOption) => handleSelectionChange('company', selectedOption)} />
        <FilterDropdown label="Skills" options={skillsOptions} onChange={(selectedOption) => handleSelectionChange('skills', selectedOption)} />
        <FilterDropdown label="Role" options={jobCategoryOptions} onChange={(selectedOption) => handleSelectionChange('jobCategory', selectedOption)} />
        <FilterDropdown label="Side" options={marketSideOptions} onChange={(selectedOption) => handleSelectionChange('marketSide', selectedOption)} />
      </div>
      <FilterSelectionDisplay selectedFilters={selectedFilters} onClearFilters={resetFilters} onRemoveFilter={handleRemoveFilter} includeSearch={true}/>
    </div>
  );
};

export default FilterComponent;