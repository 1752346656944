import React, { useState, useEffect } from 'react';
import { fetchTalentById, updateTalentDetails } from './talentApi.js';
import { FileUploaderMinimal } from "@uploadcare/react-uploader";
import "@uploadcare/react-uploader/core.css";
import "./TalentForm.css";
import { useClerk } from '@clerk/clerk-react';

function TalentForm({ userID = '' }) { 
  const { openSignIn } = useClerk();
  const [talentDetails, setTalentDetails] = useState({
    userID: '',
    phoneNumber: '',
    filename: '',
    fileID: '',
  });

  const [isFormChanged, setIsFormChanged] = useState(false);
  const [errors, setErrors] = useState({});
  const [consent, setConsent] = useState(false);

  useEffect(() => {
    const loadTalentDetails = async () => {
      if (!userID) {
        return;
      }
      
      try {
        const data = await fetchTalentById(userID);
        if (!data) {
          throw new Error('No talent details found');
        } else {
          setTalentDetails({
            userID: userID,
            phoneNumber: data.phone || '',
            filename: data.filename || '',
          });
          setConsent(!!data.phone);
        }
      } catch (error) {
        console.error('Failed to load talent details:', error.message);
        alert('Failed to load talent details');
      }
    };

    loadTalentDetails();
  }, [userID]);

  const handleChange = (e) => {
    if (!userID) {
      openSignIn({ forceRedirectUrl: 'https://quant-jobs.com/talentPool' });
      return;
    }
    const { name, value } = e.target;
    setTalentDetails(prevState => ({
      ...prevState,
      [name]: value
    }));
    setIsFormChanged(true);
  };

  const handleFileUpload = (file) => {
    if (!userID) {
      openSignIn({ forceRedirectUrl: 'https://quant-jobs.com/talentPool' });
      return;
    }
    setTalentDetails(prevState => ({
      ...prevState,
      filename: file.name,
      fileID: file.uuid
    }));
    setIsFormChanged(true);
  };

  const handleConsentChange = (e) => {
    if (!userID) {
      openSignIn({ forceRedirectUrl: 'https://quant-jobs.com/talentPool' });
      return;
    }
    setConsent(e.target.checked);
    setIsFormChanged(true);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!talentDetails.phoneNumber) {
      newErrors.phoneNumber = 'Phone number is required';
    }
    if (!talentDetails.filename) {
      newErrors.filename = 'Filename is required';
    }
    if (!consent) {
      newErrors.consent = 'Consent is required';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    try {
      const response = await updateTalentDetails(talentDetails.userID, talentDetails.phoneNumber, talentDetails.filename, talentDetails.fileID);
      if (response.error) {
        console.error('Failed to update talent details:', response.error);
        alert(`Failed to update talent details: ${response.error}`);
      } else {
        setIsFormChanged(false);
        alert('Talent details updated successfully!');
      }
    } catch (error) {
      console.error('Failed to update talent details:', error);
      alert('Failed to update talent details. Please check your network connection and try again.');
    }
  };

  return (
    <div className="talent-portal">
      <form onSubmit={handleSubmit} className="form">
        <div className="form-input">
          <label className="form-label">User ID: </label>
          <input
            type="text"
            name="userID"
            value={talentDetails.userID}
            readOnly
            className="form-input"
            placeholder="Sign in to continue"
            style={{
              backgroundColor: '#F5F5F5',
            }}
          />
        </div>

        <div className="form-input">
          <label className="form-label">Phone Number: </label>
          <input
            type="text"
            name="phoneNumber"
            value={talentDetails.phoneNumber}
            onChange={handleChange}
            className="form-input"
            maxLength="15"
            onFocus={() => { if (!userID) openSignIn({ forceRedirectUrl: 'https://quant-jobs.com/talentPool' }) }}
          />
          {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
        </div>

        <label className="form-label">Resume/ CV: </label>
        <div className="file-uploader-container">
          <FileUploaderMinimal
            pubkey="1f1b4b95872b029b29ed"
            maxLocalFileSizeBytes={1000000}
            multiple={false}
            onFileUploadSuccess={handleFileUpload}
          />
          {talentDetails.filename && (
            <div className="uploaded-filename">
              <strong>Uploaded File:</strong> {talentDetails.filename}
            </div>
          )}
        </div>

        <div className="form-input">
          <label className="form-label">
            <input
              type="checkbox"
              name="consent"
              checked={consent}
              onChange={handleConsentChange}
              onFocus={() => { if (!userID) openSignIn({ forceRedirectUrl: 'https://quant-jobs.com/talentPool' }) }}
            />
            Yes, I am happy to be contacted by quant-jobs.com
          </label>
          {errors.consent && <span className="error">{errors.consent}</span>}
        </div>

        <button type="submit" className={`form-button ${isFormChanged ? 'active' : 'inactive'}`} disabled={!isFormChanged}>Apply</button>
      </form>
    </div>
  );
}

export default TalentForm;
