import axios from 'axios';

const API_URL = process.env.REACT_APP_FLASK_API_URL || 'http://localhost:3001';
const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;

export const jobs_count = async (filters) => {
    try {
        const res = await axios.post(`${API_URL}/jobs_count`, { filters: filters }, {
        headers: {
            'Authorization': AUTH_TOKEN
        }
        });

        const jsonData = res.data;
        return jsonData;
    } catch (error) {
        console.error("An error occurred while fetching data: ", error);
        return [];
    }
};

export const percentage_by_country = async (filters) => {
    try {
        const res = await axios.post(`${API_URL}/percentage_by_country`, { filters: filters }, {
        headers: {
            'Authorization': AUTH_TOKEN
        }
        });

        const jsonData = res.data;
        return jsonData;
    } catch (error) {
        console.error("An error occurred while fetching data: ", error);
        return [];
    }
};

export const percentage_by_employment_type = async (filters) => {
    try {
        const res = await axios.post(`${API_URL}/percentage_by_employment_type`, { filters: filters }, {
        headers: {
            'Authorization': AUTH_TOKEN
        }
        });

        const jsonData = res.data;
        return jsonData;
    } catch (error) {
        console.error("An error occurred while fetching data: ", error);
        return [];
    }
};


export const percentage_by_job_title = async (filters) => {
    try {
        const res = await axios.post(`${API_URL}/percentage_by_job_title`, { filters: filters }, {
        headers: {
            'Authorization': AUTH_TOKEN
        }
        });

        const jsonData = res.data;
        return jsonData;
    } catch (error) {
        console.error("An error occurred while fetching data: ", error);
        return [];
    }
};

export const percentage_by_work_arrangement = async (filters) => {
    try {
        const res = await axios.post(`${API_URL}/percentage_by_work_arrangement`, { filters: filters }, {
        headers: {
            'Authorization': AUTH_TOKEN
        }
        });

        const jsonData = res.data;
        return jsonData;
    } catch (error) {
        console.error("An error occurred while fetching data: ", error);
        return [];
    }
};

export const average_pay_by_country = async (filters, currency) => {
    try {
        const res = await axios.post(`${API_URL}/average_pay_by_country`, { filters: filters, currency: currency }, {
        headers: {
            'Authorization': AUTH_TOKEN
        }
        });

        const jsonData = res.data;
        return jsonData;
    } catch (error) {
        console.error("An error occurred while fetching data: ", error);
        return [];
    }
};

export const average_pay_by_role = async (filters, currency) => {
    try {
        const res = await axios.post(`${API_URL}/average_pay_by_role`, { filters: filters, currency: currency }, {
        headers: {
            'Authorization': AUTH_TOKEN
        }
        });

        const jsonData = res.data;
        return jsonData;
    } catch (error) {
        console.error("An error occurred while fetching data: ", error);
        return [];
    }
};

export const skill_count = async (filters) => {
    try {
        const res = await axios.post(`${API_URL}/skill_count`, { filters: filters }, {
        headers: {
            'Authorization': AUTH_TOKEN
        }
        });

        const jsonData = res.data;
        return jsonData;
    } catch (error) {
        console.error("An error occurred while fetching data: ", error);
        return [];
    }
};

export const average_pay_by_skill = async (filters, currency) => {
    try {
        const res = await axios.post(`${API_URL}/average_pay_by_skill`, { filters: filters, currency: currency }, {
        headers: {
            'Authorization': AUTH_TOKEN
        }
        });

        const jsonData = res.data;
        return jsonData;
    } catch (error) {
        console.error("An error occurred while fetching data: ", error);
        return [];
    }
};

export const average_max_salary_boost = async (filters) => {
    try {
        const res = await axios.post(`${API_URL}/average_max_salary_boost`, { filters: filters }, {
        headers: {
            'Authorization': AUTH_TOKEN
        }
        });

        const jsonData = res.data;
        return jsonData;
    } catch (error) {
        console.error("An error occurred while fetching data: ", error);
        return [];
    }
};

export const count_by_company = async (filters) => {
    try {
        const res = await axios.post(`${API_URL}/count_by_company`, { filters: filters }, {
        headers: {
            'Authorization': AUTH_TOKEN
        }
        });

        const jsonData = res.data;
        return jsonData;
    } catch (error) {
        console.error("An error occurred while fetching data: ", error);
        return [];
    }
};

export const count_by_industry = async (filters) => {
    try {
        const res = await axios.post(`${API_URL}/count_by_industry`, { filters: filters }, {
        headers: {
            'Authorization': AUTH_TOKEN
        }
        });

        const jsonData = res.data;
        return jsonData;
    } catch (error) {
        console.error("An error occurred while fetching data: ", error);
        return [];
    }
};

export const count_by_institution = async (filters) => {
    try {
        const res = await axios.post(`${API_URL}/count_by_institution`, { filters: filters }, {
        headers: {
            'Authorization': AUTH_TOKEN
        }
        });

        const jsonData = res.data;
        return jsonData;
    } catch (error) {
        console.error("An error occurred while fetching data: ", error);
        return [];
    }
};

export const count_by_source = async (filters) => {
    try {
        const res = await axios.post(`${API_URL}/count_by_source`, { filters: filters }, {
        headers: {
            'Authorization': AUTH_TOKEN
        }
        });

        const jsonData = res.data;
        return jsonData;
    } catch (error) {
        console.error("An error occurred while fetching data: ", error);
        return [];
    }
};

export const get_distinct_values = async (columns) => {
    try {
        const res = await axios.post(`${API_URL}/distinct_values`, { columns }, {
            headers: {
                'Authorization': AUTH_TOKEN
            }
        });

        const jsonData = res.data;
        return jsonData;
    } catch (error) {
        console.error("An error occurred while fetching data: ", error);
        return [];
    }
};

