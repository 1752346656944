import React from 'react';
import './EmployerJobsIntro.css';

const EmployerJobsIntro = ({ company }) => {
    const today = new Date();
    const maxActivationDate = new Date(today);
    maxActivationDate.setDate(today.getDate() + 60); // Adds 60 days to the current date

    return (
        <div className="job-portal-intro">

            <div className="about-section">
                <div className="content-wrapper">
                    <div className="content-text">
                        <p>Update <strong>{company}</strong> info below!</p>
                        <p>Changes made may take up to <strong>10 minutes</strong> to be reflected in the application.</p>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default EmployerJobsIntro;
