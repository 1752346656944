import React from 'react';
import { Link } from 'react-router-dom';
import './SideBarLink.css'; // Importing the CSS file for styles

function SideBarLink() {
  const imageUrl = `${process.env.PUBLIC_URL}/images/Let the Quant Jobs Come to You!.png`;
  return (
    <Link to="/MyFilters" className="sidebar-link">
        <img src={imageUrl} alt="Feed Image" />
    </Link>
  );
}

export default SideBarLink;