import React, { useState, useEffect } from 'react';
import JobCardWrapper from './JobCardWrapper';
import JobForm from './JobForm';
import { deleteJob } from '../api';
import ChangesReview from './ChangesReview';
import SaveReminder from './saveReminder';
import './JobGrid.css';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modalOverlay">
        <div className="modalContent">
            <span className="closeIcon" onClick={onClose} role="button" aria-label="close">&times;</span>
            {children}
        </div>
    </div>
  );
};

const JobGrid = ({ jobs, directListing, company, uniqueColumnValues, user, refreshJobs, isPrivileged, jobLimit }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentJobID, setCurrentJobID] = useState(null);
  const [jobsStatusBefore, setJobsStatusBefore] = useState([]);
  const [jobsStatusAfter, setJobsStatusAfter] = useState([]);
  const [updatedJobs, setUpdatedJobs] = useState(jobs);
  const [changedJobIDs, setChangedJobIDs] = useState([]);

  useEffect(() => {
    if (directListing) {
      const today = new Date();
      const todayIso = today.toISOString().split('T')[0];

      const updatedJobs = jobs.map(job => {
        let jobExpiresDate = new Date(job.expires);
        if (isNaN(jobExpiresDate)) {
          jobExpiresDate = today;
        }

        const expiresIso = jobExpiresDate < today ? todayIso : jobExpiresDate.toISOString().split('T')[0];
        return {
          jobID: job.jobID,
          expires: expiresIso,
          active: job.active
        };
      });

      setJobsStatusBefore(updatedJobs);
      setJobsStatusAfter([...updatedJobs]);
    }
  }, [jobs, directListing]);

  // Effect to update the jobs with jobsStatusAfter
  useEffect(() => {
    const newJobs = jobs.map(job => {
      const statusUpdate = jobsStatusAfter.find(j => j.jobID === job.jobID);
      if (statusUpdate) {
        return { ...job, ...statusUpdate };
      }
      return job;
    });
    setUpdatedJobs(newJobs);
  }, [jobsStatusAfter, jobs]);


  useEffect(() => {
  const newChangedJobIDs = jobsStatusBefore.reduce((acc, job, index) => {
      const correspondingAfterJob = jobsStatusAfter[index];
      if (!correspondingAfterJob || job.jobID !== correspondingAfterJob.jobID) {
        return acc; // Skip if no corresponding job or mismatch in IDs
      }
      // Check if any relevant field differs
      if (job.active !== correspondingAfterJob.active || job.expires !== correspondingAfterJob.expires) {
        acc.push(job.jobID);
      }
      return acc;
    }, []);
    setChangedJobIDs(newChangedJobIDs);
  }, [jobsStatusBefore, jobsStatusAfter]);


  const handleEditJob = (jobID) => {
    setCurrentJobID(jobID);
    setModalOpen(true);
  };

  const onJobStatusChange = (jobID, updatedStatus) => {

    const today = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()));

    setJobsStatusAfter(currentJobsStatus => {
      return currentJobsStatus.map(job => {
        if (job.jobID === jobID) {
          const updatedExpiresDate = new Date(updatedStatus.expires);
          const expiresIso = updatedExpiresDate.toISOString().split('T')[0];
          const isActive = updatedExpiresDate > today; // Use today here
          return {
            ...job,
            expires: expiresIso,
            active: isActive ? 1 : 0
          };
        }
        return job;
      });
    });
  };

  const onClearChanges = (jobID) => {
    setJobsStatusAfter(currentJobsStatus => {
      const jobBefore = jobsStatusBefore.find(job => job.jobID === jobID);
      if (!jobBefore) return currentJobsStatus;
      
      return currentJobsStatus.map(job => {
        if (job.jobID === jobID) {
          return { ...jobBefore };
        }
        return job;
      });
    });
  };

  const onDelete = async (jobID) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this job?");

    if (isConfirmed) {
      const response = await deleteJob(jobID);
      if (response.success) {
        alert("Job successfully deleted.");
        refreshJobs();
      } else {
        // If the deletion was not successful, inform the user
        alert(response.message || "An error occurred while deleting the job.");
        refreshJobs();
      }
    }
  };

  return (
    <div className="job-container-outer">
      <p className="job-listing-type">{directListing ? 'Manually Added Jobs:' : 'Externally Sourced Jobs:'}</p>
      {directListing && (
        <SaveReminder jobsStatusBefore={jobsStatusBefore} jobsStatusAfter={jobsStatusAfter}/>
      )}
      <div className="job-container-grid">
        <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
          <JobForm jobID={currentJobID} company={company} uniqueColumnValues={uniqueColumnValues} isPrivileged={isPrivileged} email={user.emailAddresses[0].emailAddress} onSuccess={() => { setModalOpen(false); refreshJobs(); }}/>
        </Modal>
          {updatedJobs.map((job, index) => {
            const jobStatusB4 = jobsStatusBefore.find(j => j.jobID === job.jobID) || {};
            const jobStatus = jobsStatusAfter.find(j => j.jobID === job.jobID) || {};
            // Check if this job's jobID is in the changedJobIDs array
            const isChanged = changedJobIDs.includes(job.jobID);
            return (
              <div className={`job-grid-item ${!job['active'] ? 'inactive' : 'active'} ${isChanged ? 'changed' : ''}`} key={index}>
                <JobCardWrapper
                  job={job}
                  {...(directListing ? { onEditJob: handleEditJob } : {})}
                  {...(directListing ? { onDelete: onDelete } : {})}
                  directListing={directListing}
                  jobStatus={jobStatus}
                  hasChanges={jobStatus.expires !== jobStatusB4.expires}
                  onJobStatusChange={onJobStatusChange}
                  onClearChanges={onClearChanges}
                  refreshJobs={refreshJobs}
                />
              </div>
            );
          })}
        {directListing && (
          <div className="job-grid-item">
            <JobCardWrapper
              {...(directListing ? { onEditJob: handleEditJob } : {})}
              directListing={directListing}
              jobStatus={{}}
              onJobStatusChange={onJobStatusChange}
              onClearChanges={onClearChanges}
            />
          </div>
        )}
      </div>
      {directListing && (
        <ChangesReview jobsStatusBefore={jobsStatusBefore} jobsStatusAfter={jobsStatusAfter} onSuccess={() => { refreshJobs() }} jobLimit={jobLimit} />
      )}
    </div>
  );
};

export default JobGrid;
