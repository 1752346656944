import axios from 'axios';
import CryptoJS from 'crypto-js';

const API_URL = process.env.REACT_APP_FLASK_API_URL || 'http://localhost:3001';
const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;
const enc_string = process.env.REACT_APP_ENC_KEY;
const ENC_KEY = CryptoJS.enc.Utf8.parse(enc_string);

const decryptData = (encryptedData) => {
    // Decode the base64 encoded data
    const rawData = CryptoJS.enc.Base64.parse(encryptedData);

    // Extract the IV, which is the first 16 bytes of the decoded data
    const iv = CryptoJS.lib.WordArray.create(rawData.words.slice(0, 4), 16);

    // Extract the encrypted message, which is the rest of the data
    const encryptedMessage = CryptoJS.lib.WordArray.create(rawData.words.slice(4), rawData.sigBytes - 16);

    // Decrypt the message
    const decryptedMessage = CryptoJS.AES.decrypt({ ciphertext: encryptedMessage }, ENC_KEY, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    // Attempt to convert decrypted message to UTF-8
    try {
        const decryptedText = decryptedMessage.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedText);
    } catch (e) {
        console.error("Failed to parse decrypted text as JSON:", e);
        return null; // or handle error appropriately
    }
};

export const fetchJobDetail = async (jobID) => {
  try {
    const res = await axios.get(`${API_URL}/api/jobDetail/${jobID}`, {
      headers: {
        'Authorization': AUTH_TOKEN
      }
    });

    const jsonData = decryptData(res.data);
    return jsonData;
  } catch (error) {
    console.error("An error occurred while fetching job detail: ", error);
    // Return an empty object instead of null in case of an error
    return {};
  }
};

export const fetchEmployerByCompany = async (companyName) => {
  try {
    const response = await axios.get(`${API_URL}/employers/${companyName}`, {
      headers: {
        'Authorization': AUTH_TOKEN
      }
    });
    const jsonData = decryptData(response.data);
    return jsonData;
  } catch (error) {
    console.error("An error occurred while fetching employer details: ", error);
    return null;
  }
};

export const updateEmployerDetails = async (employerData) => {
  try {
    const response = await axios.post(`${API_URL}/update_employer`, employerData, {
      headers: {
        'Authorization': AUTH_TOKEN,
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error("An error occurred while updating employer details: ", error);
    return { error: error.response?.data?.error || "An unknown error occurred" };
  }
};

export const fetchCompanyByUser = async (userEmail) => {
  try {
    const response = await axios.get(`${API_URL}/get-company-by-user`, {
      params: {
        user: userEmail
      },
      headers: {
        'Authorization': AUTH_TOKEN
      }
    });
    const jsonData = decryptData(response.data);
    return jsonData;
  } catch (error) {
    console.error("An error occurred while fetching company by user: ", error);
    // Adapt the error handling as necessary based on your error response structure
    return { error: error.response?.data?.error || "An unknown error occurred" };
  }
};

export const updateJobDetails = async (jobData) => {
  try {
    // Check if jobData contains a nested jobDetails key and remove it if present
    if (jobData.jobDetails) {
      jobData = jobData.jobDetails;
    }

    const response = await axios.post(`${API_URL}/update_job`, { jobDetails: jobData }, {
      headers: {
        'Authorization': AUTH_TOKEN,
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error("An error occurred while updating job details: ", error);
    return { error: error.response?.data?.error || "An unknown error occurred" };
  }
};

export const fetchJobsByCompany = async (directListing, companyName) => {
  try {
    const res = await axios.get(`${API_URL}/getJobsByCompany`, {
      params: {
        direct_listing: directListing,
        company_name: companyName
      },
      headers: {
        'Authorization': AUTH_TOKEN
      }
    });
    const jsonData = decryptData(res.data);
    return jsonData;
  } catch (error) {
    console.error("An error occurred while fetching jobs by company: ", error);
    return [];
  }
};

export const getJobLimit = async (company) => {
  try {
    const res = await axios.get(`${API_URL}/get_job_limit/${company}`, {
      headers: {
        'Authorization': AUTH_TOKEN
      }
    });
    const jsonData = decryptData(res.data);
    return jsonData;
  } catch (error) {
    console.error("An error occurred while fetching direct listing: ", error);
    return null;
  }
};

export const getDirectListing = async (company) => {
  try {
    const res = await axios.get(`${API_URL}/get_direct_listing/${company}`, {
      headers: {
        'Authorization': AUTH_TOKEN
      }
    });
    const jsonData = decryptData(res.data);
    return jsonData;
  } catch (error) {
    console.error("An error occurred while fetching direct listing: ", error);
    return null;
  }
};


export const setDirectListing = async (company, directListing) => {
  try {
    const res = await axios.post(`${API_URL}/set_direct_listing/${company}`, {
      directListing
    }, {
      headers: {
        'Authorization': AUTH_TOKEN,
        'Content-Type': 'application/json'
      }
    });
    return res.data;
  } catch (error) {
    console.error("An error occurred while setting direct listing: ", error);
    return null;
  }
};


export const fetchJobDetailByID = async (jobID) => {
  try {
    const res = await axios.get(`${API_URL}/fetchJobDetailByID`, {
      params: {
        jobID: jobID
      },
      headers: {
        'Authorization': AUTH_TOKEN
      }
    });
    const jsonData = decryptData(res.data)
    return jsonData;
  } catch (error) {
    console.error("An error occurred while fetching job: ", error);
    return [];
  }
};


export const updateJobExpirationAndActiveStatus = async (jobID, expires) => {
  try {
    const res = await axios.post(`${API_URL}/update_job_expiry`, {
      jobID,
      expires
    }, {
      headers: {
        'Authorization': AUTH_TOKEN
      }
    });
    return res.data;
  } catch (error) {
    console.error("An error occurred while updating job: ", error);
    // Rethrowing error to be caught by the caller
    throw error;
  }
};


export const fetchUniqueColumnValues = async (columns) => {
  try {
    const res = await axios.post(`${API_URL}/get-unique-column-values`, {
      columns // This is the list of column names you want to query for unique values
    }, {
      headers: {
        'Authorization': AUTH_TOKEN,
        'Content-Type': 'application/json'
      }
    });

    const jsonData = decryptData(res.data);

    const filteredData = {};
    Object.keys(jsonData).forEach(column => {
      // Filter out null values from each column's array
      filteredData[column] = jsonData[column].filter(value => value !== null);
    });
    return filteredData;
  } catch (error) {
    console.error("An error occurred while fetching unique column values: ", error);
    return null;
  }
};


export const deleteJob = async (jobID) => {
  try {
    const res = await axios.post(`${API_URL}/delete_job/${jobID}`, {}, {
      headers: {
        'Authorization': AUTH_TOKEN
      }
    });

    // Assuming the response is JSON and contains a message
    const message = res.data.message;
    console.log("Response message: ", message);
    return res.data; // Return the whole response data
  } catch (error) {
    console.error("An error occurred while deleting the job: ", error);
    return { success: false, message: "Failed to delete the job" }; // Return a default error object
  }
};


export const fetchFavouriteJobs = async (userId) => {
  try {
    // Replace 'favourite_jobs' with the correct path if necessary
    const res = await axios.get(`${API_URL}/favourite_jobs/${userId}`, {
      headers: {
        'Authorization': AUTH_TOKEN
      }
    });

    // Assuming the response data is encrypted, we decrypt it
    const jsonData = decryptData(res.data);
    return jsonData;
  } catch (error) {
    console.error("An error occurred while fetching favourite jobs: ", error);
    return [];
  }
};


export const setFavouriteJob = (userId, jobId, should_add) => {
  axios.post(`${API_URL}/set_favourite_job/${userId}/${jobId}/${should_add}`, {}, {
    headers: {
      'Authorization': AUTH_TOKEN
    }
  })
  .then(response => {
    // Updated the log message to match the new functionality
    console.log('Job favorite status updated successfully', response.data);
  })
  .catch(error => {
    // Error handling
    console.error("An error occurred while updating the favourite job status: ", error);
  });

  // Return immediately without waiting for the response
  return;
};

export const incrementLinkClick = async (jobID) => {
  try {
    const res = await axios.post(`${API_URL}/api/incrementLinkClick/${jobID}`, {}, {
      headers: {
        'Authorization': AUTH_TOKEN
      }
    });
    console.log("Link click incremented successfully: ", res.data);
    return res.data; // Return the response data
  } catch (error) {
    console.error("An error occurred while incrementing the link click: ", error);
    return { success: false, message: "Failed to increment the link click" }; // Return a default error object
  }
};

export const checkBackendAndDBStatus = async () => {
  try {
    const res = await axios.get(`${API_URL}/ping`, {
      headers: {
        'Authorization': AUTH_TOKEN
      }
    });
    // Assuming the response status is a good indicator of success
    return res.data.status === 'success';
  } catch (error) {
    console.error("An error occurred while checking backend and DB status: ", error);
    return false;
  }
};

export const getActiveUsersCount = async () => {
  try {
    const res = await axios.get(`${API_URL}/active_users`, {
      headers: {
        'Authorization': AUTH_TOKEN
      }
    });
    return res.data; // Return the response data
  } catch (error) {
    console.error("An error occurred while retrieving the active users count: ", error);
    return { success: false, message: "Failed to retrieve the active users count" }; // Return a default error object
  }
};
