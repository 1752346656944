import React from 'react';
import JobProperty from './jobProperty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark as faBookmarkSolid, faFire } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as faBookmarkRegular, faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import './analysisPage/customTooltip.css';
import './jobCard.css';

const JobCard = ({ job, onBookmark = null, onCardClick = null, maxSkillCharacters=80, userCurrency, onEdit = null, onDelete = null, onDuplicate = null}) => {
  if (!job) {
    return <div className="job-card-horizontal">Please select a job</div>;
  }

  const {
    jobTitle,
    company,
    location,
    country,
    minPay,
    maxPay,
    currency,
    employmentType,
    experience,
    primarySkills,
    seniority,
    workArrangement,
    added,
    briefSummary,
    jobCategory,
    industry,
    marketSide,
    promoted,
    popular,
    views,
    maxSalary_AUD, 
    maxSalary_CAD, 
    maxSalary_CHF, 
    maxSalary_CNY,
    maxSalary_EUR, 
    maxSalary_GBP, 
    maxSalary_HKD, 
    maxSalary_INR, 
    maxSalary_JPY,
    maxSalary_NZD, 
    maxSalary_SGD, 
    maxSalary_USD, 
    minSalary_AUD, 
    minSalary_CAD,
    minSalary_CHF, 
    minSalary_CNY, 
    minSalary_EUR, 
    minSalary_GBP,
    minSalary_HKD, 
    minSalary_INR, 
    minSalary_JPY, 
    minSalary_NZD,
    minSalary_SGD, 
    minSalary_USD
  } = job;

  const renderPayRange = () => {
    const minSalaryKey = `minSalary_${userCurrency}`;
    const maxSalaryKey = `maxSalary_${userCurrency}`;
    const minSalary = job[minSalaryKey];
    const maxSalary = job[maxSalaryKey];

    if (!minSalary && !maxSalary) return null;
    const pay = minSalary && maxSalary ? `${minSalary} - ${maxSalary}` : maxSalary ? `Up to ${maxSalary}` : `${minSalary}`;
    return `${pay} ${userCurrency}`;
  };

  const renderSkills = () => {
    const allSkills = [...new Set([...primarySkills, ...job.secondarySkills])].sort((a, b) => a.length - b.length);
    let displayedSkills = [];
    let charactersCount = 0;

    for (let skill of allSkills) {
      if ((charactersCount + skill.length) <= maxSkillCharacters) {
        displayedSkills.push(skill);
        charactersCount += skill.length + 2; // Adding 2 for the comma and space
      } else {
        break;
      }
    }

    const moreSkillsCount = allSkills.length - displayedSkills.length;

    return (
      <>
        {displayedSkills.map((skill, index) => (
          <JobProperty key={index} category="primary" value={skill} />
        ))}
        {moreSkillsCount > 0 && displayedSkills.length > 0 && <JobProperty category="" value={`+ ${moreSkillsCount} more`} />}
      </>
    );
  };

  const renderJobCat = () => {
    let displayedCat = [];
    let charactersCount = 0;
    const maxCharacters = 80; // Maximum number of characters to display

    for (let cat of jobCategory) {
      if ((charactersCount + cat.length) <= maxCharacters) {
        displayedCat.push(cat);
        charactersCount += cat.length + 2; // Adding 2 for the comma and space
      } else {
        break;
      }
    }

    const moreCatCount = jobCategory.length - displayedCat.length;

    return (
      <>
        {displayedCat.map((cat, index) => (
          <JobProperty key={index} category="jobCategory" value={cat} />
        ))}
        {moreCatCount > 0 && <JobProperty category="jobCategory" value={`+ ${moreCatCount} more`} />}
      </>
    );
  };

  const renderBookmark = () => {
    return onBookmark !== null ? (
      job.star ? 
        <FontAwesomeIcon 
          className="gold-star-icon1" 
          icon={faBookmarkSolid} 
          color="#2FC01A"
          onClick={() => onBookmark(job.jobID)}
        /> :
        <FontAwesomeIcon 
          className="star-icon-grey1" 
          icon={faBookmarkRegular} 
          color="#A4A4A4"
          onClick={() => onBookmark(job.jobID)}
        />
    ) : null;
  };

  const renderEditButton = () => {
    if (onEdit) {
      return (
        <button
          className="edit-btn"
          onClick={() => onEdit(job.jobID)}
        >
          EDIT
        </button>
      );
    }
    return null;
  };

  const renderDeleteButton = () => {
    if (onDelete) {
      return (
        <button
          className="delete-btn" // Make sure you have appropriate CSS for this
          onClick={() => onDelete(job.jobID)}
        >
          DELETE
        </button>
      );
    }
    return null;
  };

  const renderDuplicateButton = () => {
    if (onDuplicate) {
      return (
        <button
          className="duplicate-btn" // Make sure you have appropriate CSS for this
          onClick={() => onDuplicate(job)}
        >
          DUPLICATE
        </button>
      );
    }
    return null;
  };

  const renderViews = () => {
    if (onDuplicate) {
      return <strong className="views-count">{`${views || 0}+ views`}</strong>;
    }
    return null;
  };

  const renderTopRight = () => {
    return <span className="job-top-right starbar-container" onClick={(e) => e.stopPropagation()}>
        {added}
        {popular && <FontAwesomeIcon className="fire-icon" icon={faFire} />}
        {renderBookmark()}
    </span>;
  };

  return (
    <div className='jobCard-outer-container'>

      <div className="button-container">
        <div className="views-container">
          {renderViews()}
        </div>
        <div className="actions-container">
          {renderEditButton()}
          {renderDuplicateButton()}
          {renderDeleteButton()}
        </div>
      </div>

      <div className="job-card-horizontal" onClick={onCardClick}>
        {renderTopRight()}

        <div className="job-card-section1 job-card-left">
          <div className='company-and-icon'>
            <div className='job-company1'>{company}</div>
            {briefSummary && (
              <span className="info-icon" data-tip={briefSummary}>
                <FontAwesomeIcon className="question-icon" icon={faCircleQuestion} color="#606060" />
                <span className="info-tooltip">{briefSummary}</span>
              </span>
            )}
          </div>
          <div className='job-title1'>{jobTitle}</div>
          <div >
          {location && country ? `${location}, ${country}` : location || country}
          </div>
          {promoted &&
            <div className='promoted'>
              <JobProperty category="promoted" value={"Promoted Job"} />
            </div>
          }
        </div>

        <div className="job-card-section1 job-card-middle">
          <div className="job-skills-container">
            {renderSkills()}
          </div>
        </div>

        <div className="job-card-section1 job-card-right">

        <div className='job-right-items'>
            {jobCategory && renderJobCat()}
            {marketSide && <JobProperty category="marketSide" value={marketSide} />}

            {experience ? <JobProperty category="experience" value={experience} /> : seniority && <JobProperty category="seniority" value={seniority} />}
          
            {renderPayRange() && <JobProperty category="pay" value={renderPayRange()} />}
            {workArrangement && <JobProperty category="workArrangement" value={workArrangement} />}
            {employmentType && <JobProperty category="employmentType" value={employmentType} />}
          </div>

        </div>
      </div>
    </div>
  );
};  

export default JobCard;
