import React, { useEffect, useState } from 'react';
import PagesNav from './pagesNav';
import SortDropdown from './SortDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './TopBar.css'; // Make sure this path is correct

const TopBar = ({ resultsCount, currentPage, onNav, selectedFilters, onFilterChange }) => {

  const [isEmpty, setIsEmpty] = useState(true);

  useEffect(() => {
    setIsEmpty(Object.keys(selectedFilters).every(key => 
      key === 'sort' ? true : (Array.isArray(selectedFilters[key]) ? selectedFilters[key].length === 0 : selectedFilters[key] === '')
    ));
  }, [selectedFilters]);

  const handleSortChange = (selectedOption) => {
    const updatedFilters = { ...selectedFilters, sort: selectedOption };
    onFilterChange(updatedFilters);
  };

    return (
        <div className="topBar">
            <div className={`bar-container subscribe-button ${isEmpty ? 'empty-filters' : 'active-filters'}`}>
                <Link to="/myfilters" className="subscribe-link" style={{ pointerEvents: isEmpty ? 'none' : 'auto' }}>
                    <FontAwesomeIcon 
                      className="paper-plane"
                      icon={faPaperPlane}
                    />
                    Subscribe to Filter
                </Link>
            </div>
            <div className="bar-container">
              {resultsCount} Results
            </div>
            <div className="bar-container">
              <PagesNav resultsCount={resultsCount} currentPage={currentPage} onNav={onNav} />
            </div>
            <div className="bar-container">
              <SortDropdown currentValue={selectedFilters.sort} onChange={handleSortChange} />
            </div>
            <div className="bar-container"></div>
        </div>
    );
};

export default TopBar;
