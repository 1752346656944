import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, CircleMarker, Tooltip, useMap } from "react-leaflet";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './bubbleMap.css'
import { getAllLatLong } from '../api_query_jobs'; // Adjust the path as necessary

const BubbleMap = ({filteredJobsLocations, locationFilters, setLocationFilters}) => {
  const [allJobs, setAllJobs] = useState({});
  const scalingFactor = 2; // Control the size of the bubbles proportionally

  useEffect(() => {
    const fetchData = async () => {
      const data = await getAllLatLong();
      setAllJobs(data);
    };

    fetchData().catch(console.error);
  }, []);

  const handleCircleClick = (location) => {
    setLocationFilters([...locationFilters, location]);
  };


  const SelectAllControl = ({ onSelectAll }) => {
    const map = useMap();

    useEffect(() => {
      const control = new L.Control({ position: 'bottomleft' });

      control.onAdd = function () {
        const container = L.DomUtil.create('div', 'leaflet-bar select-all-control');
        const button = L.DomUtil.create('button', 'select-all-button', container);
        button.innerText = 'Select All';
        button.onclick = function(e) {
          L.DomEvent.stopPropagation(e);
          onSelectAll();
        };

        L.DomEvent.disableClickPropagation(container);

        return container;
      };

      control.addTo(map);

      return () => {
        control.remove();
      };
    }, [map, onSelectAll]);

    return null;
  };

  const selectAll = () => {
    setLocationFilters([]);
  };


  return (
    <div className='outer-map-container'>
      <MapContainer 
        center={[35, 10]} 
        zoom={2.5} 
        style={{ height: "100%", width: "100%" }}
        zoomControl={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {Object.entries(allJobs).map(([location, { latitude, longitude, count }], index) => {
          if (latitude && longitude) {
            const isFiltered = filteredJobsLocations.hasOwnProperty(location);
            const fillColor = isFiltered ? "blue" : "grey";
            return (
              <CircleMarker
                key={`${latitude},${longitude}-${fillColor}-${index}`} // Modified key to include index
                center={[latitude, longitude]}
                color={fillColor}
                weight={0}
                fillColor={fillColor}
                fillOpacity={0.5}
                radius={Math.sqrt(count) * scalingFactor} // Radius based on job count
                eventHandlers={{
                  click: () => handleCircleClick(location),
                }}
              >
                <Tooltip>{location}</Tooltip>
              </CircleMarker>
            );
          } else {
            return null;
          }
        })}
      </MapContainer>
    </div>
  );
};

export default BubbleMap;
