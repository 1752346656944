import React from 'react';
import JobsReel from './jobsReel/jobsReel.jsx';
import MapAndSearch from './map/MapAndSearch.jsx';
import TopBar from './topBar/TopBar.jsx';
import SideBarLink from '../filterSubscribe/SideBarLink.jsx';
import './browseJobsPage.css';

const BrowseJobsPage = ({jobs, totalResults, selectedFilters, setSelectedFilters, currentPage, setCurrentPage, filteredJobsLocations, isLoading, currency, activeUsersCount}) => {

  return (
    <div>
      <div className="intro-container">
        <div className="intro">Uncover Global Opportunities in the World of Quant</div>
      </div>
      <div className="map-container1">
        <MapAndSearch selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} filteredJobsLocations={filteredJobsLocations} activeUsersCount={activeUsersCount}/>
      </div>
      <TopBar resultsCount={totalResults} currentPage={currentPage} onNav={setCurrentPage} selectedFilters={selectedFilters} onFilterChange={setSelectedFilters}/>
      <div className="browse-jobs-page">
        <div className="left-container"></div>
        <div className="reel-container">
          <JobsReel jobs={jobs} totalResults={totalResults} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} currentPage={currentPage} setCurrentPage={setCurrentPage} isLoading={isLoading} currency={currency}/>
        </div>
        <div className="right-container">
          <SideBarLink selectedFilters={selectedFilters}/>
        </div>
      </div>
    </div>
  );
  
};
export default BrowseJobsPage;