import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import JobCard from '../jobCard'; // Adjust the path as necessary
import useFavouriteJobs from '../utils/manageFavouriteJobs.js';
import FilterComponent from '../browseJobs/map/FilterComponent';
import './mobileJobBoard.css';
import BarTwoMob from '../browseJobs/topBar/barTwoMobile.jsx'
import PagesNav from '../browseJobs/topBar/pagesNav.jsx';
import TopBar from '../browseJobs/topBar/TopBarMoblie.jsx';
import { CircularProgress } from '@mui/material';

function MobileKdbJobs({ jobs, totalResults, selectedFilters, setSelectedFilters, currentPage, setCurrentPage, isLoading }) {
  const { favouriteJobsList, toggleFavouriteJobForUser } = useFavouriteJobs();
  const [jobsEnriched, setJobsEnriched] = useState([]);

  useEffect(() => {
      const updatedJobs = jobs.map(job => ({
        ...job,
        star: favouriteJobsList.includes(job.jobID), // Check if job is in favouriteJobsList
      }));
      setJobsEnriched(updatedJobs);
  }, [jobs, favouriteJobsList]);

  const navigate = useNavigate();
  const handleJobClick = (jobID) => {
    navigate(`/job/${jobID}`);
  };

  return (
    <div className="page">
      <div className="intro-container-mobile">
        <h1 className="intro-mobile">Uncover Global Opportunities in the World of Quant</h1>
      </div>
      <div className="job-cards-container-mob">
        <div className="search-and-filter">
          <FilterComponent selectedFilters={selectedFilters} onFilterChange={setSelectedFilters} />
        </div>
        <TopBar resultsCount={totalResults} currentPage={currentPage} onNav={setCurrentPage} selectedFilters={selectedFilters} onFilterChange={setSelectedFilters}/>
        
        {isLoading ? (
          <div className="loading-container">
            <CircularProgress />
          </div>
        ) : totalResults === 0 ? (
          <div className="no-jobs-message">
            <div style={{ fontSize: '2em' }}>🤔</div>
            <div>No Jobs Found!</div>
            <div>Try Adjusting Your Filters</div>
          </div>
        ) : (
          <>
            <BarTwoMob resultsCount={totalResults} currentPage={currentPage} onNav={setCurrentPage} selectedFilters={selectedFilters} />
            {jobsEnriched.map(job => (
              <div key={job.id}>
                <JobCard job={job} onBookmark={toggleFavouriteJobForUser} onCardClick={() => handleJobClick(job.jobID)} maxSkillsCharacters={0} maxSkillCharacters={30} />
              </div>
            ))}
            <PagesNav resultsCount={totalResults} currentPage={currentPage} onNav={setCurrentPage} />
          </>
        )}

      </div>
    </div>
  );
}

export default MobileKdbJobs;
