import React, { useState, useEffect } from 'react';
import PagesNav from './pagesNav.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './TopBarMobile.css'; // Make sure this path is correct

const BarTwoMob = ({ resultsCount, currentPage, onNav, selectedFilters}) => {

    const [isEmpty, setIsEmpty] = useState(true);

    useEffect(() => {
      setIsEmpty(Object.keys(selectedFilters).every(key => 
        key === 'sort' ? true : (Array.isArray(selectedFilters[key]) ? selectedFilters[key].length === 0 : selectedFilters[key] === '')
      ));
    }, [selectedFilters]);

    return (
        <div className="topBar-mob">
            <div className="results-nav-container-mob">
              <div className="half-width-mob">
                <div className={`bar-container subscribe-button ${isEmpty ? 'empty-filters' : 'active-filters'}`}>
                    <Link to="/myfilters" className="subscribe-link" style={{ pointerEvents: isEmpty ? 'none' : 'auto' }}>
                        <FontAwesomeIcon 
                        className="paper-plane"
                        icon={faPaperPlane}
                        />
                    </Link>
                </div>
              </div>
              <div className="half-width-mob1">
                <PagesNav resultsCount={resultsCount} currentPage={currentPage} onNav={onNav} />
              </div>
              <div className="half-width-mob empty-div-mob">
                <FontAwesomeIcon className="paper-plane" icon={faPaperPlane} style={{ opacity: 0 }} />
              </div>
            </div>
        </div>
    );
};

export default BarTwoMob;
