import axios from 'axios';
import CryptoJS from 'crypto-js';

const API_URL = process.env.REACT_APP_FLASK_API_URL || 'http://localhost:3001';
const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;
const enc_string = process.env.REACT_APP_ENC_KEY;
const ENC_KEY = CryptoJS.enc.Utf8.parse(enc_string);

const decryptData = (encryptedData) => {
    // Decode the base64 encoded data
    const rawData = CryptoJS.enc.Base64.parse(encryptedData);

    // Extract the IV, which is the first 16 bytes of the decoded data
    const iv = CryptoJS.lib.WordArray.create(rawData.words.slice(0, 4), 16);

    // Extract the encrypted message, which is the rest of the data
    const encryptedMessage = CryptoJS.lib.WordArray.create(rawData.words.slice(4), rawData.sigBytes - 16);

    // Decrypt the message
    const decryptedMessage = CryptoJS.AES.decrypt({ ciphertext: encryptedMessage }, ENC_KEY, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    // Attempt to convert decrypted message to UTF-8
    try {
        const decryptedText = decryptedMessage.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedText);
    } catch (e) {
        console.error("Failed to parse decrypted text as JSON:", e);
        return null; // or handle error appropriately
    }
};

export const fetchTalentById = async (id) => {
    try {
      const response = await axios.get(`${API_URL}/talent/${id}`, {
        headers: {
          'Authorization': AUTH_TOKEN
        }
      });
      const jsonData = decryptData(response.data);
      return jsonData;
    } catch (error) {
      console.error("An error occurred while fetching talent details: ", error);
      return null;
    }
};

export const updateTalentDetails = async (userID, phone, filename, fileID) => {
    try {
        const response = await axios.post(`${API_URL}/update_talent`, {
            userID,
            phone,
            filename,
            fileID
        }, {
            headers: {
                'Authorization': AUTH_TOKEN,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error("An error occurred while updating talent details: ", error);
        return null;
    }
};