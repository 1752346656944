import React, { useState, useEffect } from 'react';
import Masonry from 'react-masonry-css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand, faShare, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
//import { faShareAlt } from '@fortawesome/free-regular-svg-icons';
import { useLocation } from 'react-router-dom'; // Assuming you are using React Router
import MailchimpForm from '../utils/MailchimpForm';
import { Helmet } from 'react-helmet';
import './Masonry.css';

const MasonryPage = () => {

	const [expandedImage, setExpandedImage] = useState(null);
	const location = useLocation();

	const [images, setImages] = useState([
		{ src: 'Quant Map - North America.png', description: 'Data: Quantitative Finance Jobs June 2024 - North America', date: 'June 03, 2024', tag: 'Quant Trading Jobs USA', buttonText: 'View Post' },
		{ src: 'Quant Map - Europe.png', description: 'Data: Quantitative Finance Jobs June 2024 - Europe', date: 'June 03, 2024', tag: 'Quant Trading Jobs London', buttonText: 'View Post' },
		{ src: 'Quant Map - APAC.png', description: 'Data: Quantitative Finance Jobs June 2024 - APAC', date: 'June 03, 2024', tag: 'Quant Trading Jobs APAC', buttonText: 'View Post' },
		{ src: 'Ranking of Best Financial Engineering programs - Mathematical Finance programs.png', path: 'https://quantnet.com/mfe-programs-rankings/', description: 'Data: QuantNet MFE Rankings - Avg Salary x Employment %', date: 'May 30, 2024', tag: 'Quant Salary Distributions', buttonText: 'View Source' },
		{ src: 'Quants Work Arrangements.png', description: 'Data: Most quant jobs are now back in the office', date: 'May 20, 2024', buttonText: 'View Post' },
	  	{ src: 'Quant Salary box plot.png', description: 'Data: Salary distributions for different quant roles', date: 'May 20, 2024', tag: 'Quant Salary Distributions', buttonText: 'View Post' },
	]);

	const breakpointColumnsObj = {
		default: 4, // default number of columns
		1500: 3, // when the viewport width is 1100px or greater
		700: 2, // when the viewport width is 700px or greater
		500: 1 // when the viewport width is 500px or greater
	  };

	useEffect(() => {
		// Parse the URL query parameters
		const params = new URLSearchParams(location.search);
		const imageSrc = params.get('image'); // Assuming the parameter name is 'image'
		if (imageSrc) {
			handleFullScreen(`${process.env.PUBLIC_URL}/Feed/${imageSrc}`);
		}
	}, [location]);

	const handleFullScreen = (src, event) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		setExpandedImage(src); // Store the expanded image's src in state
		document.body.classList.add('modal-open');
	};

	// Call this function to close the expanded image
	const closeFullScreen = () => {
		setExpandedImage(null);
		document.body.classList.remove('modal-open');
	};

	// If there is an expanded image, display it in fullscreen mode
	useEffect(() => {
	    if (expandedImage) {
	        const imageObj = images.find(img => `${process.env.PUBLIC_URL}/Feed/${img.src}` === expandedImage);
	        const img = document.createElement('img');
	        img.src = expandedImage;
	        img.classList.add('fullscreen-image');
	        const overlay = document.createElement('div');
	        overlay.classList.add('fullscreen-overlay');

	        const imageContainer = document.createElement('div');
	        imageContainer.classList.add('fullscreen-image-container');
	        imageContainer.appendChild(img);
	        overlay.appendChild(imageContainer);

	        if (imageObj) {
	            const desc = document.createElement('div');
	            desc.classList.add('fullscreen-description');
	            desc.textContent = imageObj.description;
	            const date = document.createElement('div');
	            date.classList.add('fullscreen-date');
	            date.textContent = imageObj.date;

	            const textContainer = document.createElement('div');
	            textContainer.classList.add('fullscreen-text-container');
	            textContainer.appendChild(desc);
	            textContainer.appendChild(date);

	            // Check if a link exists and is not empty
				if (imageObj.path && imageObj.path.trim() !== '') {
				    // Existing 'See More' link element
				    const linkElement = document.createElement('a');
				    linkElement.href = imageObj.path;
				    linkElement.textContent = imageObj.buttonText || 'See More';
				    linkElement.classList.add('fullscreen-link');
				    linkElement.setAttribute('target', '_blank');
				    linkElement.setAttribute('rel', 'noopener noreferrer');
				    textContainer.appendChild(linkElement);
				}

			    const shareButton = document.createElement('button');
			    shareButton.textContent = 'Share'; // Text on the button
			    shareButton.classList.add('fullscreen-share-button'); // Add your CSS class for styling
			    shareButton.onclick = () => handleShare(imageObj.src); // Use arrow function to avoid losing this context
			    textContainer.appendChild(shareButton); // Append the button next to the 'See More' link

	            overlay.appendChild(textContainer);
	        }

	        document.body.appendChild(overlay);
	        overlay.addEventListener('click', closeFullScreen);
	        return () => {
	            overlay.removeEventListener('click', closeFullScreen);
	            document.body.removeChild(overlay);
	        };
	    }
	}, [expandedImage, images]);


	const handleShare = (src, event) => {
	    if (event) {
	        event.preventDefault(); // Prevent the link navigation.
	        event.stopPropagation(); // Stop the event from bubbling up to parent elements.
	    }
	    const baseURL = window.location.href.split('?')[0]; // Gets the base URL without any query parameters.
	    const shareURL = `${baseURL}?image=${encodeURIComponent(src)}`;
	    
	    // Check if the Web Share API is supported
	    if (navigator.share) {
	        // Mobile devices
	        navigator.share({
	            title: 'Check this out!', // Title of the share
	            url: shareURL, // The URL to share
	            text: 'I found something interesting!' // Text to accompany the URL
	        }).then(() => {
	            console.log('Thanks for sharing!');
	        })
	        .catch(console.error);
	    } else {
	        // Fallback for Desktop
	        navigator.clipboard.writeText(shareURL).then(() => {
	            alert("Link copied to clipboard!"); // Feedback to the user
	        }, (err) => {
	            console.error('Could not copy text: ', err); // Error handling
	        });
	    }
	};


	return (
	  <div className="feed-page">

      	<Helmet>
          <title>Quant Trends - Data Driven Quant Content, News & Updates</title>
          <meta name="description" content="Explore the latest trends, data, and insights in the world of KDB+. Join our community to stay ahead." />
          <meta property="og:title" content="KDB+ Trends" />
          <meta property="og:description" content="Explore the latest trends, data, and insights in the world of KDB+. Join our community to stay ahead." />
          <meta property="og:type" content="website" />
			<meta property="og:image" content={`${process.env.PUBLIC_URL}/preview_images/${"kdbTrends.png"}`} />
          <meta property="og:url" content={window.location.href} />
        </Helmet>

		  <div className="intro-container">
	        <MailchimpForm />
	      </div>
	    <Masonry
	      breakpointCols={breakpointColumnsObj}
	      className="my-masonry-grid"
	      columnClassName="my-masonry-grid_column">
	      {images.map((item, index) => (
	        <div key={index} className={`image-container ${index === 0 ? 'double-width' : ''}`}>
	          <a href={item.path || "#"} onClick={(event) => !item.path && handleFullScreen(`${process.env.PUBLIC_URL}/Feed/${item.src}`, event)} target="_blank" rel="noopener noreferrer">
	            <img src={`${process.env.PUBLIC_URL}/Feed/${item.src}`} alt={item.tag || `Item ${index}`} />
	            <div className="image-overlay">
	              <span className="image-description">{item.description}</span>
	              <span className="image-date">{item.date}</span>
	            </div>
				<FontAwesomeIcon icon={faShare} onClick={(event) => handleShare(item.src, event)} className="share-icon" />
				<FontAwesomeIcon icon={faExpand} onClick={(event) => handleFullScreen(`${process.env.PUBLIC_URL}/Feed/${item.src}`, event)} className="expand-icon" />
				<FontAwesomeIcon icon={faQuestionCircle} onClick={(event) => handleFullScreen(`${process.env.PUBLIC_URL}/Feed/${item.src}`, event)} className="question-mark-icon" />
	          </a>
	        </div>
	      ))}
	    </Masonry>
	  </div>
	);
};

export default MasonryPage;
