import React, { useState, useEffect } from 'react';
import './chartsGrid.css';
import GenericBarChart from './barChart.jsx';
import GenericPieChart from './pieChart.jsx';
import GenericBarChartHorizontal from './barChartHorizontal.jsx';
import GenericLineChart from './lineChart.jsx';
import { CircularProgress } from '@material-ui/core';
import {
  percentage_by_country, 
  percentage_by_employment_type, 
  percentage_by_job_title, 
  percentage_by_work_arrangement, 
  average_pay_by_country, 
  average_pay_by_role, 
  skill_count, 
  average_pay_by_skill, 
  average_max_salary_boost, 
  count_by_company, 
  count_by_industry, 
  count_by_institution,
  count_by_source
} from './apiAnalysisPage.jsx';

const ChartsGrid = ({ currency, visibilityFlags, filters }) => {
  const [countByCountry, setCountByCountry] = useState([]);
  const [countByCountryLoading, setCountByCountryLoading] = useState(true);
  const [countByEmploymentType, setCountByEmploymentType] = useState([]);
  const [countByEmploymentTypeLoading, setCountByEmploymentTypeLoading] = useState(true);
  const [countByWorkArrangement, setCountByWorkArrangement] = useState([]);
  const [countByWorkArrangementLoading, setCountByWorkArrangementLoading] = useState(true);
  const [countByJobTitle, setCountByJobTitle] = useState([]);
  const [countByJobTitleLoading, setCountByJobTitleLoading] = useState(true);
  const [avgPayByCountry, setAvgPayByCountry] = useState([]);
  const [avgPayByCountryLoading, setAvgPayByCountryLoading] = useState(true);
  const [avgPayByRole, setAvgPayByRole] = useState([]);
  const [avgPayByRoleLoading, setAvgPayByRoleLoading] = useState(true);
  const [skillCount, setSkillCount] = useState([]);
  const [skillCountLoading, setSkillCountLoading] = useState(true);
  const [avgPayBySkillPairing, setAvgPayBySkillPairing] = useState([]);
  const [avgPayBySkillPairingLoading, setAvgPayBySkillPairingLoading] = useState(true);
  const [countByCompany, setCountByCompany] = useState([]);
  const [countByCompanyLoading, setCountByCompanyLoading] = useState(true);
  const [avgSalaryBoostBySkill, setAvgSalaryBoostBySkill] = useState([]);
  const [avgSalaryBoostBySkillLoading, setAvgSalaryBoostBySkillLoading] = useState(true);
  const [countByIndustry, setCountByIndustry] = useState([]);
  const [countByIndustryLoading, setCountByIndustryLoading] = useState(true);
  const [countByInstitutionType, setCountByInstitutionType] = useState([]);
  const [countByInstitutionTypeLoading, setCountByInstitutionTypeLoading] = useState(true);
  const [countBySource, setCountBySource] = useState([]);
  const [countBySourceLoading, setCountBySourceLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

/////// aggregations for data for each chart
useEffect(() => {
  if (visibilityFlags.countByCountry) calculateCountByCountry(filters);
  if (visibilityFlags.countByEmploymentType) calculateCountByEmploymentType(filters);
  if (visibilityFlags.countByWorkArrangement) calculateCountByWorkArrangement(filters);
  if (visibilityFlags.countByJobTitle) calculateCountByJobTitle(filters);
  if (visibilityFlags.skillCount) calculateSkillCount(filters);
  if (visibilityFlags.countByCompany) calculateCountByCompany(filters);
  if (visibilityFlags.countByIndustry) calculateCountByIndustry(filters);
  if (visibilityFlags.countByInstitutionType) calculateCountByInstitution(filters);
  if (visibilityFlags.countBySource) calculateCountBySource(filters);
  if (visibilityFlags.avgSalaryBoostBySkill) calculateAvgMaxSalaryBoost(filters);
}, [visibilityFlags, filters]);

useEffect(() => {
  if (visibilityFlags.avgPayByCountry) calculateAvgPayByCountry(filters, currency);
  if (visibilityFlags.avgPayByRole) calculateAvgPayByRole(filters, currency);
  if (visibilityFlags.payBySkillPairing) calculateAvgPayBySkill(filters, currency);
}, [visibilityFlags, filters, currency]);

// set isMobile
useEffect(() => {
  const checkMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  // Check on mount (in case the user is already on a mobile device)
  checkMobile();

  // Add event listener for window resize
  window.addEventListener('resize', checkMobile);

  // Cleanup function to remove the event listener
  return () => window.removeEventListener('resize', checkMobile);
}, []);


///////////////////////////////////////////////////
///////////////Aggregation functions///////////////

//////////////// job count by country ////////////
const calculateCountByCountry = async (filters) => {
  setCountByCountryLoading(true);
  const calculatedPercentByCountry = await percentage_by_country(filters);
  setCountByCountry(calculatedPercentByCountry);
  setCountByCountryLoading(false);
};

//////////////// count by employmentType ////////////

const calculateCountByEmploymentType = async (filters) => {
  setCountByEmploymentTypeLoading(true);
  const CountByEmploymentType = await percentage_by_employment_type(filters);
  setCountByEmploymentType(CountByEmploymentType);
  setCountByEmploymentTypeLoading(false);
};

//////////////// job count by jobTitle ////////////

  const calculateCountByJobTitle = async (filters) => {
    setCountByJobTitleLoading(true);
    const CountByJobTitle = await percentage_by_job_title(filters);
    setCountByJobTitle(CountByJobTitle);
    setCountByJobTitleLoading(false);
  };

//////////////// count by workArrangement ////////////

  const calculateCountByWorkArrangement = async (filters) => {
    setCountByWorkArrangementLoading(true);
    const CountByWorkArrangement = await percentage_by_work_arrangement(filters);
    setCountByWorkArrangement(CountByWorkArrangement);
    setCountByWorkArrangementLoading(false);
  };

//////////////// maxSalary by country ////////////

  const calculateAvgPayByCountry = async (filters, currency) => {
    setAvgPayByCountryLoading(true);
    const AvgPayByCountry = await average_pay_by_country(filters, currency);
    setAvgPayByCountry(AvgPayByCountry);
    setAvgPayByCountryLoading(false);
  };

//////////////// maxSalary by country ////////////

const calculateAvgPayByRole = async (filters, currency) => {
  setAvgPayByRoleLoading(true);
  const AvgPayByRole = await average_pay_by_role(filters, currency);
  setAvgPayByRole(AvgPayByRole);
  setAvgPayByRoleLoading(false);
};

//////////////// skill count ////////////

  const calculateSkillCount = async (filters) => {
    setSkillCountLoading(true);
    const SkillCount = await skill_count(filters);
    setSkillCount(SkillCount);
    setSkillCountLoading(false);
  };

//////////////// avgPay by skill pairing ////////////

  const calculateAvgPayBySkill = async (filters, currency) => {
    setAvgPayBySkillPairingLoading(true);
    const AvgPayBySkill = await average_pay_by_skill(filters, currency);
    setAvgPayBySkillPairing(AvgPayBySkill);
    setAvgPayBySkillPairingLoading(false);
  };

//////////////// avg salary boost per skill ////////////

  const calculateAvgMaxSalaryBoost = async (filters) => {
    setAvgSalaryBoostBySkillLoading(true);
    const AvgMaxSalaryBoost = await average_max_salary_boost(filters);
    setAvgSalaryBoostBySkill(AvgMaxSalaryBoost);
    setAvgSalaryBoostBySkillLoading(false);
  };

//////////////// job count by company ////////////

  const calculateCountByCompany = async (filters) => {
    setCountByCompanyLoading(true);
    const CountByCompany = await count_by_company(filters);
    setCountByCompany(CountByCompany);
    setCountByCompanyLoading(false);
  };

//////////////// job count by industry ////////////

  const calculateCountByIndustry = async (filters) => {
    setCountByIndustryLoading(true);
    const CountByIndustry = await count_by_industry(filters);
    setCountByIndustry(CountByIndustry);
    setCountByIndustryLoading(false);
  };

//////////////// job count by institution ////////////

  const calculateCountByInstitution = async (filters) => {
    setCountByInstitutionTypeLoading(true);
    const CountByInstitution = await count_by_institution(filters);
    setCountByInstitutionType(CountByInstitution);
    setCountByInstitutionTypeLoading(false);
  };

//////////////// Employer vs Recruiter ////////////

  const calculateCountBySource = async (filters) => {
    setCountBySourceLoading(true);
    const CountBySource = await count_by_source(filters);
    setCountBySource(CountBySource);
    setCountBySourceLoading(false);
  };


///////////// html /////////////
  return (
    <div className="container-grid">

      {/* top employers by count */}
      {visibilityFlags.countByCompany && (
        <div className="grid-item">
          {countByCompanyLoading ? <CircularProgress /> :
          <GenericBarChartHorizontal
            data={countByCompany}
            dataKey="company"
            dataValue="percentage"
            title="Top Employers"
            yAxisAngle={-15}
            nameFormatter={(company) => "company"}
            valueFormatter={(percentage) => {
              const value = Number(percentage); // Convert to number if it's not already
              const rounded = Number(value.toPrecision(2)); // Round to 3 significant figures
              return `${rounded.toLocaleString()}%`; // Convert to string with percentage sign
            }}
            yAxisFontSize = {'10px'}
            infoDescription ="Number of job posts by employer as a percentage of all jobs within the filters you set - top 12 results"
          />}
        </div>
      )}

      {/* job count by country pie chart */}
      {visibilityFlags.countByCountry && (
        <div className="grid-item">
         {countByCountryLoading ? <CircularProgress /> :
         <GenericPieChart
          data={countByCountry}
          dataKey="country"
          dataValue="percentage"
          title="Jobs Count by Country"
          nameFormatter={(country) => country || 'Unknown'}
          valueFormatter={(value, name) => [`${name}: ${Number(value).toPrecision(2)}%`]}
          infoDescription ="Count of job posts by country"
        />}
        </div>
      )}

      {/* job count by employmentType pie chart */}
      {visibilityFlags.countByEmploymentType && (
        <div className="grid-item">
         {countByEmploymentTypeLoading ? <CircularProgress /> :
         <GenericPieChart
          data={countByEmploymentType}
          dataKey="type"
          dataValue="percentage"
          title="Jobs Count by Employment Type"
          nameFormatter={(type) => type || 'Unknown'}
          valueFormatter={(value, name) => [`${name}: ${Number(value).toPrecision(2)}%`]}
        />}
        </div>
      )}

      {/* job count by workArrangement pie chart */}
      {visibilityFlags.countByWorkArrangement && (
        <div className="grid-item">
         {countByWorkArrangementLoading ? <CircularProgress /> :
         <GenericPieChart
          data={countByWorkArrangement}
          dataKey="type"
          dataValue="percentage"
          title="Jobs Count by Work Arrangement"
          nameFormatter={(type) => type || 'Unknown'}
          valueFormatter={(value, name) => [`${name}: ${Number(value).toPrecision(2)}%`]}
        />}
        </div>
      )}

      {visibilityFlags.countByJobTitle && (
        <div className="grid-item">
          {countByJobTitleLoading ? <CircularProgress /> :
          <GenericBarChartHorizontal
            data={countByJobTitle}
            dataKey="title"
            dataValue="percentage"
            title="Quant Roles"
            nameFormatter={(type) => type || 'Unknown'}
            valueFormatter={(value, name) => [`${name}: ${Number(value).toPrecision(2)}%`]}
            yAxisFontSize = {'10px'}
            infoDescription ="Count of jobs by role category as a percentage of filtered jobs. Each job may belong to more than one category."
          />}
        </div>
      )}

      {/* pay by country bar chart */}
      {visibilityFlags.avgPayByCountry && (
          <div className="grid-item">
              {avgPayByCountryLoading ? <CircularProgress /> :
              <GenericBarChart
                  data={avgPayByCountry}
                  dataKey="country"
                  dataValue="avgSalary"
                  title="Pay by Country"
                  nameFormatter={(currency) => `Adjusted Annual Pay (${currency})`}
                  valueFormatter={(value) => `${value.toLocaleString()} ${currency}`}
                  xAxisAngle={-30}
                  xAxisFontSize={'10px'}
                  yAxisFontSize={'12px'}
                  infoDescription="Average advertised pay by country for filtered data"
                  maxBars={isMobile ? 15 : 30}  // Use the isMobile state to set maxBars
              />}
          </div>
      )}

      {/* pay by role bar chart */}
      {visibilityFlags.avgPayByRole && (
          <div className="grid-item">
              {avgPayByRoleLoading ? <CircularProgress /> :
              <GenericBarChart
                  data={avgPayByRole}
                  dataKey="jobCategory"
                  dataValue="avgSalary"
                  title="Pay by Role"
                  nameFormatter={(currency) => `Adjusted Annual Pay (${currency})`}
                  valueFormatter={(value) => `${value.toLocaleString()} ${currency}`}
                  xAxisAngle={-30}
                  xAxisFontSize={'10px'}
                  yAxisFontSize={'12px'}
                  infoDescription="Average advertised pay by role for filtered data. Each job may belong to more than one category."
                  maxBars={isMobile ? 15 : 30}  // Use the isMobile state to set maxBars
              />}
          </div>
      )}

      {/* pay by skill pairing bar chart */}
      {visibilityFlags.avgSalaryBoostBySkill && (
        <div className="grid-item">
         {avgSalaryBoostBySkillLoading ? <CircularProgress /> :
         <GenericBarChart
          data={avgSalaryBoostBySkill}
          dataKey="skill"
          dataValue="percentageDiff"
          title="% Pay Gain by Skill"
          nameFormatter={(skill) => skill || 'Unknown'}
          valueFormatter={(value) => [`Pay Diff: ${Number(value).toPrecision(2)}%`]}
          xAxisAngle = {-45}
          xAxisFontSize = {'10px'}
          yAxisFontSize = {'12px'}
          infoDescription ="Salary difference between job posts with and without the mention of each skill."
          maxBars={isMobile ? 15 : 30}
        />}
        </div>
      )}

      {visibilityFlags.skillCount && (
        <div className="grid-item">
          {skillCountLoading ? <CircularProgress /> :
          <GenericBarChartHorizontal
            data={skillCount}
            dataKey="skill"
            dataValue="percentage"
            title="Skills Frequency"
            nameFormatter={(currency) => `Adjusted Annual Pay (${currency})`}
            valueFormatter={(percentage) => {
              const value = Number(percentage); // Convert to number if it's not already
              const rounded = Number(value.toPrecision(2)); // Round to 3 significant figures
              return `${rounded.toLocaleString()}%`; // Convert to string with percentage sign
            }}
            yAxisFontSize = {'10px'}
            infoDescription ="Occurances of a skill appearing in job posts as a percentage of filtered jobs - top 12 results"
          />}
        </div>
      )}

      {/* pay by skill pairing bar chart */}
      {visibilityFlags.payBySkillPairing && (
        <div className="grid-item">
         {avgPayBySkillPairingLoading ? <CircularProgress /> :
         <GenericBarChart
          data={avgPayBySkillPairing}
          dataKey="skill"
          dataValue="avgPay"
          title="Highest paying Skills"
          nameFormatter={(currency) => `Adjusted Annual Pay (${currency})`}
          valueFormatter={(value) => `${value.toLocaleString()} ${currency}`}
          xAxisAngle = {-30}
          xAxisFontSize = {'12px'}
          yAxisFontSize = {'12px'}
          infoDescription ="Average advertised pay by skill"
        />}
        </div>
      )}

      {/* job count by industry pie chart */}
      {visibilityFlags.countByIndustry && (
        <div className="grid-item">
         {countByIndustryLoading ? <CircularProgress /> :
         <GenericPieChart
          data={countByIndustry}
          dataKey="industry"
          dataValue="percentage"
          title="Jobs Count by Industry"
          nameFormatter={(industry) => industry || 'Unknown'}
          valueFormatter={(value, name) => [`${name}: ${Number(value).toPrecision(2)}%`]}
          infoDescription ="Count of job posts by industry"
        />}
        </div>
      )}

      {visibilityFlags.countByInstitutionType && (
        <div className="grid-item">
          {countByInstitutionTypeLoading ? <CircularProgress /> :
          <GenericBarChartHorizontal
            data={countByInstitutionType}
            dataKey="institutionType"
            dataValue="percentage"
            title="Jobs Count by Institution Type"
            nameFormatter={(institutionType) => institutionType || 'Unknown'}
            valueFormatter={(percentage) => {
              const value = Number(percentage); // Convert to number if it's not already
              const rounded = Number(value.toPrecision(2)); // Round to 3 significant figures
              return `${rounded.toLocaleString()}%`; // Convert to string with percentage sign
            }}
            yAxisFontSize = {'10px'}
            yAxisAngle={-15}
            infoDescription ="Count of job posts by institution type"
          />}
        </div>
      )}

      {/* job count by source: employer/recruiter */}
      {visibilityFlags.countBySource && (
        <div className="grid-item">
         {countBySourceLoading ? <CircularProgress /> :
         <GenericPieChart
          data={countBySource}
          dataKey="type"
          dataValue="percentage"
          title="Jobs Count by Employer vs Recruiter"
          nameFormatter={(type) => type || 'Unknown'}
          valueFormatter={(value, name) => [`${name}: ${Number(value).toPrecision(2)}%`]}
        />}
        </div>
      )}
      

    </div>

  );

};

export default ChartsGrid;
