import axios from 'axios';

const API_URL = process.env.REACT_APP_FLASK_API_URL || 'http://localhost:3001';
const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;

export const upsertFilterSubscriptions = async (userID, filters) => {
  console.log(filters)
  try {
    const response = await axios.post(`${API_URL}/filter_subscriptions`, {
      userID,
      filters
    }, {
      headers: {
        Authorization: AUTH_TOKEN
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error upserting filter subscriptions:", error);
    throw error;
  }
};

export const fetchFilterSubscriptions = async (userID) => {
  try {
    const response = await axios.get(`${API_URL}/filter_subscriptions/${userID}`, {
      headers: {
        Authorization: AUTH_TOKEN
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching filter subscriptions:", error);
    throw error;
  }
};

export const deactivateFilterSubscription = async (filterID) => {
  try {
    const response = await axios.post(`${API_URL}/filter_subscriptions/deactivate/${filterID}`, {}, {
      headers: {
        Authorization: AUTH_TOKEN
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error deactivating filter subscription:", error);
    throw error;
  }
};

export const fetchSubscriptionDays = async (userID) => {
  try {
    const response = await axios.get(`${API_URL}/subscription_days/${userID}`, {
      headers: {
        Authorization: AUTH_TOKEN
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching subscription days:", error);
    throw error;
  }
};

export const updateSubscriptionDays = async (userID, days) => {
  try {
    const response = await axios.post(`${API_URL}/subscription_days/${userID}`, days, {
      headers: {
        Authorization: AUTH_TOKEN
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error updating subscription days:", error);
    throw error;
  }
};
