import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './FilterDropdown.css'; // Importing CSS for styling

const FilterDropdown = ({ label, options, onChange }) => {
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    if (selectedValue) {
      onChange(selectedValue);
      setSelectedValue(null);
    }
  }, [selectedValue, onChange]);

  const handleChange = value => {
    setSelectedValue(value);
  };

  // Define styles that must be applied via JS
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '15px',
      fontSize: '0.8em',
      paddingLeft: '1.2em',
      borderStyle: 'none',
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
      cursor: 'text',
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '0.8em',
      padding: 5,
    }),
    menu: (provided, state) => ({
      ...provided,
      width: 'auto',
      minWidth: '150%',
      marginLeft: '-18.75px',
      zIndex: '5',
    }),
    menuList: (provided, state) => ({
      ...provided,
    }),
  };

  // Custom Control component to include the search icon
  const ControlComponent = props => (
    <components.Control {...props}>
      <FontAwesomeIcon icon={faSearch} className="search-icon" />
      {props.children}
    </components.Control>
  );

  return (
    <Select
      options={options.map(option => ({ label: option, value: option }))}
      value={selectedValue}
      onChange={handleChange}
      className="react-select-container"
      classNamePrefix="react-select"
      isClearable={true}
      isSearchable={true}
      placeholder={label}
      components={{ Control: ControlComponent, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
      styles={customStyles} // Applying custom styles defined above
    />
  );
};

export default FilterDropdown;
