import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './jobProperty.css'; // Import the CSS file

class JobProperty extends Component {
    styles = {
        jobTitle: {
            default: { fontSize: '1.3em', fontWeight: 'bold' },
        },
        company: {
            default: { fontSize: '1.1em'},
        },
        primary: {
            default: { backgroundColor: '#F0F7FB', fontStyle: 'italic' },
        },
        secondary: {
            default: { backgroundColor: '#F0F7FB', fontStyle: 'italic' },
        },
        jobCategory: {
            quantanalyst: { color: 'white', backgroundColor: '#EF767A' },
            quantrisk: { color: 'white', backgroundColor: '#AF2BBF' },
            quantresearcher: { color: '#606060', backgroundColor: '#FFF05A' },
            quantdeveloper: { color: 'white', backgroundColor: '#378FBE' },
            quanttrader: { color: 'white', backgroundColor: '#6A4C93' },
        },
        pay: {
            default: { border: '1px solid #CCCCCC' },
        },
        promoted: {
            default: { color: 'white', backgroundColor: '#06D6A0' },
        },
        filter: {
            default: { color: 'white', backgroundColor: '#2897c7', padding: '0.5em', borderRadius: '0.5em' },
        },
        default: {}
    };

    prefixes = {
        
    };
    suffixes = {
        experience: '+ Years Exp'
    };

    getPrefix(category) {
        return this.prefixes[category] || '';
    }

    getSuffix(category) {
        const suffixKey = Object.keys(this.suffixes).find(key => category.includes(key));
        return suffixKey ? this.suffixes[suffixKey] : '';
    }

    getStyle(category, value) {
        const categoryStyles = this.styles[category];
        if (categoryStyles) {
            // Remove spaces and check if value is not null or undefined before converting to lower case
            const lowerCaseValue = value != null ? value.toString().replace(/\s+/g, '').toLowerCase() : '';
            const modifiedCategoryStyles = Object.keys(categoryStyles).reduce((acc, key) => {
                acc[key.replace(/\s+/g, '').toLowerCase()] = categoryStyles[key];
                return acc;
            }, {});

            return modifiedCategoryStyles[lowerCaseValue] || categoryStyles.default;
        }
        return this.styles.default;
    }

    getFormattedValue(category, value) {
        if (category === 'date' && value != null) {
            const valueDate = new Date(value.replace(/\./g, '-'));
            const today = new Date();
            const yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 1);

            const format = date => date.toISOString().split('T')[0];

            if (format(valueDate) === format(today)) {
                return 'today';
            } else if (format(valueDate) === format(yesterday)) {
                return 'yesterday';
            }
        } else {
            if (['primary', 'secondary', 'skill'].includes(category)) {
                value = value != null ? value.toString().toLowerCase() : '';
            } else if (category === 'pay') {
                value = value != null ? value.toString().split(' ').map(word => {
                    if (word.length === 3) {
                        return word.toUpperCase();
                    }
                    return word;
                }).join(' ') : '';
            } else {
                value = value != null ? value.toString().split(' ').map(word => {
                    if (word.toLowerCase() === 'office') {
                        return 'In-Office';
                    }
                    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                }).join(' ') : '';
            }

            if (value.toLowerCase() === 'buyside') {
                value = 'Buy-Side';
            } else if (value.toLowerCase() === 'sellside') {
                value = 'Sell-Side';
            }
        }
        return value;
    }

    decideCatagory(category, value) {
        
        if (category === 'experience') {
            const experienceValue = parseInt(value, 10);
            if (experienceValue < 3) {
                category = 'experienceJunior';
            } else if (experienceValue >= 3 && experienceValue <= 4) {
                category = 'experienceIntermediate';
            } else if (experienceValue > 4) {
                category = 'experienceSenior';
            }
        }

        return category;
    }
    
    render() {
        const { category, value, moreInfo, showCloseIcon, onClick } = this.props;
        const newcat = this.decideCatagory(category, value);
        const formattedValue = this.getFormattedValue(newcat, value); // Get formatted or original value
        const style = this.getStyle(newcat, formattedValue); // Use formattedValue for style for consistency
        const prefix = this.getPrefix(newcat);
        const suffix = this.getSuffix(newcat);

        return (
            <div className="job-property" style={style} onClick={onClick}>
                {prefix}{formattedValue}{suffix} {/* Use formattedValue here */}
                {moreInfo && (
                    <span className="info-icon" data-tip={moreInfo}>
                        <FontAwesomeIcon className="question-icon" icon={faCircleQuestion} color="#606060" />
                        <span className="info-tooltip">{moreInfo}</span>
                    </span>
                )}
                {showCloseIcon && (
                    <span className="close-icon">
                        <FontAwesomeIcon icon={faTimes} />
                    </span>
                )}
            </div>
        );
    }
}

export default JobProperty;
