import React, { useState, useEffect } from 'react';
import { fetchJobDetailByID, updateJobDetails } from '../api';
import TypeAheadDropdown from './TypeAheadDropdown';
import FilterSelectionDisplay from '../mobile/FilterSelectionDisplay'
import validateJobForm from './validateJobForm';
import './EmployersPortal.css';

function JobForm({ jobID = 0, company, uniqueColumnValues, email, isPrivileged, onSuccess }) {

const [jobDetails, setJobDetails] = useState({
    jobID: jobID,
    company: company,
    link: '',
    jobTitle: '',
    country: '',
    location: '',
    workArrangement: '',
    seniority: '',
    industry: '',
    institutionType: '',
    marketSide: '',
    employmentType: '',
    minPay: null,
    maxPay: null,
    payInterval: '',
    currency: '',
    experience: '',
    jobCategory: [],
    primarySkills: [],
    secondarySkills: [],
    description_summary: '',
    expires: jobID === 0 ? new Date().toISOString().split('T')[0] : '',
    user: email,
    email: '',
    whatsapp: '',
  });

  const fieldDisplayNames = {
    company: 'Company * ',
    jobTitle: 'Job Title * ',
    country: 'Country * ',
    location: 'City * ',
    workArrangement: 'Work Arrangement * ',
    industry: 'Industry * ',
    institutionType: 'Institution Type',
    seniority: 'Seniority Level * ',
    employmentType: 'Employment Type * ',
    minPay: 'Minimum Pay',
    maxPay: 'Maximum Pay',
    payInterval: 'Pay Interval',
    currency: 'Currency',
    experience: 'Required Experience (Years)',
    jobCategory: 'Role Category',
    primarySkills: 'Primary Skills * ',
    secondarySkills: 'Secondary Skills',
    description_summary: 'Job Description * ',
  };
 
  const [isFormChanged, setIsFormChanged] = useState(false);
  const excludeFields = ['expires', 'jobID', 'description', 'updated', 'user']; // Fields to be excluded from the form
  const readOnlyFields = isPrivileged ? [] : ['company'];

  useEffect(() => {
    const loadJobDetails = async () => {
      if (jobID == 0) {
        return;
      }
      
      try {
        const data = await fetchJobDetailByID(jobID);
        if (!data || data.length === 0) {
          throw new Error('No job details found');
        } else {
          let jobInfo = data[0]; // Assuming data is an array and we need the first item
          jobInfo.user = email;

          // Filter out keys not present in the initial state of jobDetails
          jobInfo = Object.keys(jobInfo).reduce((filtered, key) => {
            if (key in jobDetails) {
              filtered[key] = jobInfo[key];
            }
            return filtered;
          }, {});

          setJobDetails(prevState => ({
            ...prevState,
            ...jobInfo // Only update fields present in the initial state
          }));
        }
      } catch (error) {
        console.error('Failed to load job details:', error.message);
        alert('Failed to load job details');
      }
    };

    loadJobDetails();

  }, [jobID]); // Add jobDetails to the dependency array to ensure it has the initial structure


  const handleSkillChange = (value, skillType) => {
    // Assuming value is the newly selected skill
    setJobDetails(prevState => {
      // Check if the skillType array already contains 5 items
      if (prevState[skillType].length >= 5) {
        // Show an alert to the user
        alert('You can only add up to 5 skills for each type.');
        return prevState; // Return the previous state without adding a new item
      }

      // Avoid adding a skill if it's already selected
      if (!prevState[skillType].includes(value)) {
        setIsFormChanged(true);
        return {
          ...prevState,
          [skillType]: [...prevState[skillType], value] // Append the new value
        };
      }
      return prevState;
    });
  };

  const onRemoveFilter = (key, value) => {
    setJobDetails(prevState => {
      // Check if the key exists and the value is in the array
      if (prevState[key] && prevState[key].includes(value)) {
        // Filter out the value to be removed
        const updatedSkills = prevState[key].filter(item => item !== value);
        setIsFormChanged(true);
        return {
          ...prevState,
          [key]: updatedSkills
        };
      }
      return prevState; // Return the previous state if conditions are not met
    });
  };

  const onClearFilters = (skillsType) => {
    setJobDetails(prevState => {
      // Create a copy of the prevState to modify
      let newState = { ...prevState };

      if (skillsType === 'primarySkills') {
        newState.primarySkills = [];
      } else if (skillsType === 'secondarySkills') {
        newState.secondarySkills = [];
      }

      return newState;
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (!['primarySkills', 'secondarySkills'].includes(name)) {
      setJobDetails(prevState => ({
        ...prevState,
        [name]: name === 'jobCategory' ? [value] : value
      }));
    }
    setIsFormChanged(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate jobDetails
    const { isValid, errors } = validateJobForm(jobDetails);

    if (!isValid) {
      console.error('Validation failed:', errors);

      // Create a string with all error messages
      const errorMessages = Object.keys(errors).map(key => `${key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ')}: ${errors[key]}`).join('\n');

      alert(`Please correct the following errors before submitting:\n\n${errorMessages}`);
      return; // Prevent form submission if validation fails
    }

    try {
      console.log(jobDetails);
      const response = await updateJobDetails({ jobDetails });
      if (response.error) {
        console.error('Failed to update job details:', response.error);
        alert(`Failed to update job details: ${response.error}`);
      } else {
        console.log('Update successful:', response);
        alert('Job details updated successfully!\nRemember to activate this job!');
        setIsFormChanged(false);
        onSuccess();
      }
    } catch (error) {
      console.error('Failed to update job details:', error);
      alert('Failed to update job details. Please check your network connection and try again.');
    }
  };


  return (
    <div className="job-portal">
      <form onSubmit={handleSubmit} className="form">
        {Object.keys(jobDetails).filter(key => !excludeFields.includes(key) && key !== 'email' && key !== 'whatsapp' && key !== 'link').map((key) => (
          <div className="form-group" key={key}>
            <label className="form-label">
              {fieldDisplayNames[key] ? fieldDisplayNames[key] : key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ')}:
            </label>
            {key === 'primarySkills' || key === 'secondarySkills' ? (
              <>
                <FilterSelectionDisplay
                  selectedFilters={{
                    [key]: jobDetails[key] // This now uses 'skill' regardless of primary or secondary
                  }}
                  onRemoveFilter={onRemoveFilter}
                  onClearFilters={() => onClearFilters(key)}
                />
                <TypeAheadDropdown
                  options={uniqueColumnValues['skills']}
                  value={''}
                  onSelect={(val) => handleSkillChange(val, key)}
                  disabled={readOnlyFields.includes(key)}
                  placeholder={`Select ${fieldDisplayNames[key] ? fieldDisplayNames[key] : key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ')}`}
                />
              </>
            ) : ['country', 'location', 'workArrangement', 'industry', 'institutionType', 'marketSide', 'seniority', 'employmentType', 'payInterval', 'currency'].includes(key) ? (
              <TypeAheadDropdown
                options={uniqueColumnValues[key]}
                value={jobDetails[key]}
                onChange={(val) => {
                  setJobDetails({ ...jobDetails, [key]: val });
                  setIsFormChanged(true); // This line marks the form as changed
                }}
                disabled={readOnlyFields.includes(key)}
                placeholder={`Select ${fieldDisplayNames[key] ? fieldDisplayNames[key] : key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ')}`}
              />
            ) : key === 'jobCategory' ? (
              <TypeAheadDropdown
                options={uniqueColumnValues[key]}
                value={jobDetails[key][0]}
                onChange={(val) => handleChange({ target: { name: 'jobCategory', value: val } })}
                disabled={readOnlyFields.includes(key)}
                placeholder={`Select ${fieldDisplayNames[key] ? fieldDisplayNames[key] : key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ')}`}
              />
            ) : key === 'description_summary' ? (
              <textarea
                name={key}
                value={jobDetails[key]}
                onChange={handleChange}
                className="form-textarea"
                disabled={readOnlyFields.includes(key)}
                maxLength={6000}
                rows={5} // Adjust the number of rows as needed
              />
            ) : (
              <input
                type={key === 'expires' ? 'date' : key.includes('Pay') || key === 'experience' ? 'number' : 'text'}
                name={key}
                value={jobDetails[key]}
                onChange={handleChange}
                className="form-input"
                disabled={readOnlyFields.includes(key)}
                step={key.includes('Pay') ? '10000' : '1'} // Adjusting step for minPay and maxPay
              />
            )}
          </div>
        ))}

        <div className="form-group">
            <label className="form-label">Apply Link:</label>
            <input
                type="text"
                name="link"
                value={jobDetails.link}
                onChange={handleChange}
                className="form-input"
            />
        </div>
        <div className="form-group">
            <label className="form-label">Apply Email:</label>
            <input
                type="email"
                name="email"
                value={jobDetails.email}
                onChange={handleChange}
                className="form-input"
            />
        </div>
        <div className="form-group">
            <label className="form-label">Apply WhatsApp:</label>
            <input
                type="tel"
                name="whatsapp"
                pattern="\+[0-9]{1,3} [0-9]{4,14}(?:x.+)?"
                placeholder="+12 3456789012"
                value={jobDetails.whatsapp}
                onChange={handleChange}
                className="form-input"
            />
            <small className="form-text">Format: +00 0000000000</small>
        </div>

        <button type="submit" className={`form-button ${isFormChanged ? 'active' : 'inactive'}`} disabled={!isFormChanged}>Save Job</button>
      </form>
    </div>
  );
}

export default JobForm;
