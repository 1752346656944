import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ScrollToTop from './utils/ScrollToPageTop';
import reportWebVitals from './reportWebVitals';
import { ClerkProvider } from '@clerk/clerk-react';
import { BrowserRouter } from 'react-router-dom';

const publishableKey = process.env.REACT_APP_CLERK_PUBLISHABLE_HASH;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ClerkProvider 
      publishableKey={publishableKey} 
      signInForceRedirectUrl={'https://quant-jobs.com/myfilters'}
      signUpForceRedirectUrl={'https://quant-jobs.com/myfilters'}
      signInFallbackRedirectUrl={'https://quant-jobs.com/myfilters'}
      signUpFallbackRedirectUrl={'https://quant-jobs.com/myfilters'}>
      <BrowserRouter>
        <ScrollToTop/>
        <App />
      </BrowserRouter>
    </ClerkProvider>
  </React.StrictMode>
);

reportWebVitals();
