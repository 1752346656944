import ReactGA from "react-ga4";

// Check if the current environment is not localhost
const isNotLocalhost = window.location.hostname !== "localhost" && window.location.hostname !== "127.0.0.1";

if (isNotLocalhost) {
  ReactGA.initialize("G-T6CVJEJPZ0");
}

export const TriggerMessageEvent = ({ threadID }) => {
  if (isNotLocalhost) {
    ReactGA.event({
      action: "assistant_message",
      category: "from user",
      label: threadID
    });
  }
};

export const TriggerFileUploadEvent = ({ filename, status }) => {
  if (isNotLocalhost) {
    ReactGA.event({
      action: "assistant_file_upload",
      category: status,
      label: filename
    });
  }
};

export const TriggerFilterEvent = ({ filter, value }) => {
  if (value.toLowerCase().includes("all") || !isNotLocalhost) {
    return;
  }

  ReactGA.event({
    action: "analytics_filter",
    category: filter,
    label: value
  });
};
