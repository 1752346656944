import React from 'react';
import { updateJobExpirationAndActiveStatus } from '../api'; // Adjust the import path as necessary
import './ChangesReview.css';

const ChangesReview = ({ jobsStatusBefore, jobsStatusAfter, onSuccess, jobLimit }) => {
  const calculateChanges = () => {
    let becomingActiveCount = 0;
    let becomingInactiveCount = 0; // Added counter for jobs becoming inactive
    let totalDaysAdded = 0;

    jobsStatusAfter.forEach(after => {
      const before = jobsStatusBefore.find(beforeItem => beforeItem.jobID === after.jobID);
      if (before) {
        if (!before.active && after.active) {
          becomingActiveCount++;
        }
        if (before.active && !after.active) { // Check for jobs becoming inactive
          becomingInactiveCount++;
        }

        const beforeDate = new Date(before.expires);
        const afterDate = new Date(after.expires);
        const daysAdded = (afterDate - beforeDate) / (1000 * 60 * 60 * 24);
        totalDaysAdded += Math.max(daysAdded, 0);
      }
    });

    return { becomingActiveCount, becomingInactiveCount, totalDaysAdded: Math.round(totalDaysAdded) };
  };

  const countActiveJobs = () => {
    return jobsStatusAfter.filter(job => job.active).length;
  };

  const handleSubmit = async () => {
    const activeCount = countActiveJobs();
    if (activeCount > jobLimit) {
      alert(`The limit of ${jobLimit} active jobs has been exceeded. Please deactivate some jobs before proceeding. Contact admin to request more jobs.`);
      return;
    }

    try {
      for (const after of jobsStatusAfter) {
        const before = jobsStatusBefore.find(beforeItem => beforeItem.jobID === after.jobID);
        if (before && (before.expires !== after.expires || before.active !== after.active)) { // Check both expiration and active status changes
          console.log(`Job ID: ${after.jobID}, Expires: ${after.expires}, Active: ${after.active}`);
          await updateJobExpirationAndActiveStatus(after.jobID, after.expires, after.active); // Assuming your API can handle the 'active' status
        }
      }
      alert('Job expiration dates and active statuses updated successfully.');
      onSuccess && onSuccess();
    } catch (error) {
      alert(`Error updating job expiration dates and active statuses: ${error.message}`);
    }
  };

  const { becomingActiveCount, becomingInactiveCount, totalDaysAdded } = calculateChanges();
  const changesExist = becomingActiveCount > 0 || becomingInactiveCount > 0 || totalDaysAdded > 0;
  const activeCount = countActiveJobs();

  return (
    <div className={`changes-review ${changesExist ? 'has-changes' : ''}`}>
      <p>Jobs becoming active: {becomingActiveCount}</p>
      <p>Jobs becoming inactive: {becomingInactiveCount}</p> {/* Display jobs becoming inactive */}
      <p>Total days added to expiration dates: {totalDaysAdded}</p>
      <button 
        className={changesExist ? 'confirm-btn' : 'confirm-btn-grey'}
        onClick={handleSubmit} 
        disabled={!changesExist}
      >
        Confirm Changes
      </button>
    </div>
  );
};

export default ChangesReview;
