import axios from 'axios';
import CryptoJS from 'crypto-js';

const API_URL = process.env.REACT_APP_FLASK_API_URL || 'http://localhost:3001';
const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;
const enc_string = process.env.REACT_APP_ENC_KEY;
const ENC_KEY = CryptoJS.enc.Utf8.parse(enc_string);

const decryptData = (encryptedData) => {
    // Decode the base64 encoded data
    const rawData = CryptoJS.enc.Base64.parse(encryptedData);

    // Extract the IV, which is the first 16 bytes of the decoded data
    const iv = CryptoJS.lib.WordArray.create(rawData.words.slice(0, 4), 16);

    // Extract the encrypted message, which is the rest of the data
    const encryptedMessage = CryptoJS.lib.WordArray.create(rawData.words.slice(4), rawData.sigBytes - 16);

    // Decrypt the message
    const decryptedMessage = CryptoJS.AES.decrypt({ ciphertext: encryptedMessage }, ENC_KEY, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    // Attempt to convert decrypted message to UTF-8
    try {
        const decryptedText = decryptedMessage.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedText);
    } catch (e) {
        console.error("Failed to parse decrypted text as JSON:", e);
        return null; // or handle error appropriately
    }
};

export const getDropDownList = async (columns) => {
  try {
    const res = await axios.post(`${API_URL}/get_all_values`, { columns }, {
      headers: {
        'Authorization': AUTH_TOKEN
      }
    });

    const jsonData = res.data;
    return jsonData;
  } catch (error) {
    console.error("An error occurred while fetching data: ", error);
    return [];
  }
};

export const queryJobs = async (filters, currency, page) => {
  try {
    const res = await axios.post(`${API_URL}/query_jobs`, { filters, currency, page }, {
      headers: {
        'Authorization': AUTH_TOKEN
      }
    });

    const encryptedData = res.data;
    const jsonData = decryptData(encryptedData);
    return jsonData;
  } catch (error) {
    console.error("An error occurred while fetching data: ", error);
    return { total: 0, latlong:[], jobs: [] };
  }
};

export const queryJobCountOnly = async (filters) => {
  try {
    const res = await axios.post(`${API_URL}/query_job_count_only`, { filters }, {
      headers: {
        'Authorization': AUTH_TOKEN
      }
    });

    return res.data;
  } catch (error) {
    console.error("An error occurred while fetching job count: ", error);
    return 0; // Return 0 as the default job count in case of an error
  }
};


export const getAllLatLong = async () => {
  try {
    const res = await axios.get(`${API_URL}/get_all_lat_long`, {
      headers: {
        'Authorization': AUTH_TOKEN
      }
    });

    const jsonData = res.data;
    return jsonData;
  } catch (error) {
    console.error("An error occurred while fetching data: ", error);
    return [];
  }
};
