import React from 'react';
import SortDropdown from './SortDropdown';
import './TopBarMobile.css'; // Make sure this path is correct

const TopBar = ({ resultsCount, currentPage, onNav, selectedFilters, onFilterChange}) => {
    const handleSortChange = (selectedOption) => {
        const updatedFilters = { ...selectedFilters, sort: selectedOption };
        onFilterChange(updatedFilters);
    };

    return (
        <div className="topBar-mob">
            <div className="results-nav-container-mob">
              <div className="half-width-mob">
                {resultsCount} Results
              </div>
              <div className="half-width-mob1">
              <SortDropdown currentValue={selectedFilters.sort} onChange={handleSortChange} />
              </div>
            </div>
        </div>
    );
};

export default TopBar;
