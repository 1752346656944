import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import JobCard from '../../jobCard'; // Adjust the path as necessary
import useFavouriteJobs from '../../utils/manageFavouriteJobs.js';
import PagesNav from '../topBar/pagesNav.jsx';
import { CircularProgress } from '@mui/material'; // Import CircularProgress for loading indicator
import './jobsReel.css';

function JobsReel({ jobs, totalResults, currentPage, setCurrentPage, isLoading, currency }) {
  const { favouriteJobsList, toggleFavouriteJobForUser } = useFavouriteJobs();

  const navigate = useNavigate(); // Use useNavigate hook
  
  const handleJobClick = (jobID) => {
    navigate(`/job/${jobID}`); // Navigate to the job route
  };

  const [jobsEnriched, setJobsEnriched] = useState([]);

  useEffect(() => {
    const updatedJobs = jobs.map(job => ({
      ...job,
      star: favouriteJobsList.includes(job.jobID), // Check if job is in favouriteJobsList
    }));
    setJobsEnriched(updatedJobs);
  }, [jobs, favouriteJobsList]);

  return (
    <div className="page">
      <div className="job-cards-container">
        {isLoading ? (
          <div className="loading-container">
            <CircularProgress /> {/* Show loading indicator when isLoading is true */}
          </div>
        ) : jobsEnriched.length > 0 ? (
          <>
            {jobsEnriched.map((job, index) => (
              <div key={`${job.id}-${index}`}> {/* Combine job.id with index to ensure uniqueness */}
                <JobCard job={job} onBookmark={toggleFavouriteJobForUser} onCardClick={() => handleJobClick(job.jobID)} userCurrency={currency}/>
              </div>
            ))}
            <PagesNav resultsCount={totalResults} currentPage={currentPage} onNav={setCurrentPage}/>
          </>
        ) : (
          <div className="no-jobs-message">
            <div style={{ fontSize: '2em' }}>🤔</div>
            <div>No Jobs Found!</div>
            <div>Try Adjusting Your Filters</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default JobsReel;