import React from 'react';
import JobProperty from '../jobProperty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark as faBookmarkSolid, faFire } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as faBookmarkRegular } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { incrementLinkClick } from '../api';
import './jobCardVerbose.css';

const JobCard = ({ job, onBookmark = null, userCurrency }) => {
  const navigate = useNavigate();

  if (!job) {
    return <div className="job-card-horizontal">Please select a job</div>;
  }

  const {
    jobID,
    jobTitle,
    company,
    location,
    country,
    minPay,
    maxPay,
    currency,
    payInterval,
    employmentType,
    experience,
    primarySkills,
    seniority,
    workArrangement,
    added,
    posted,
    fullCompanySummary,
    source,
    jobCategory,
    industry,
    marketSide,
    link,
    description_summary,
    promoted,
    active,
    views,
    popular,
    maxPay_AUD, 
    maxPay_CAD, 
    maxPay_CHF, 
    maxPay_CNY,
    maxPay_EUR, 
    maxPay_GBP, 
    maxPay_HKD, 
    maxPay_INR, 
    maxPay_JPY,
    maxPay_NZD, 
    maxPay_SGD, 
    maxPay_USD, 
    minPay_AUD, 
    minPay_CAD,
    minPay_CHF, 
    minPay_CNY, 
    minPay_EUR, 
    minPay_GBP,
    minPay_HKD, 
    minPay_INR, 
    minPay_JPY, 
    minPay_NZD,
    minPay_SGD, 
    minPay_USD
  } = job; 

  const renderLinks = (linksString) => {
    if (!linksString || linksString.length === 0) return null;
  
    const categories = {
      linkedin: 'LinkedIn',
      indeed: 'Indeed',
      glassdoor: 'Glassdoor',
      ziprecruiter: 'ZipRecruiter',
      direct: 'Direct',
      boost: 'Boost' // Added boost category
    };
  
    const logos = {
      linkedin: `${process.env.PUBLIC_URL}/jobsite_logos/linkedin.png`,
      indeed: `${process.env.PUBLIC_URL}/jobsite_logos/indeed.png`,
      glassdoor: `${process.env.PUBLIC_URL}/jobsite_logos/glassdoor.png`,
      ziprecruiter: `${process.env.PUBLIC_URL}/jobsite_logos/ziprecruiter.png`,
      direct: `${process.env.PUBLIC_URL}/jobsite_logos/apply_direct.png`,
      boost: `${process.env.PUBLIC_URL}/jobsite_logos/boost_job.png`,
    };
  
    const links = linksString.split(',');
    const categorizedLinks = {};
  
    links.forEach(link => {
      let foundCategory = false;
      for (const category in categories) {
        if (link.toLowerCase().includes(category)) {
          categorizedLinks[category] = ensureProtocol(link.trim());
          foundCategory = true;
          break;
        }
      }
      if (link.toLowerCase().includes('checkout')) {
        categorizedLinks['boost'] = ensureProtocol(link.trim()); // Assign boost category if link contains 'checkout'
        foundCategory = true;
      }
      if (!foundCategory) {
        categorizedLinks['direct'] = ensureProtocol(link.trim());
      }
    });
  
    return (
      <div className='links-container'>
        {Object.entries(categorizedLinks).map(([category, link]) => {
          if (category === 'boost') {
            return (
              <a key={category} href={link} rel="noopener noreferrer"
                style={{ backgroundImage: `url(${logos[category]})` }}
                className={`btn btn-${category} all-job-buttons`}>
              </a>
            );
          } else {
            return (
              <a key={category} href={link} target="_blank" rel="noopener noreferrer"
                style={{ backgroundImage: `url(${logos[category]})` }}
                className={`btn btn-${category} all-job-buttons`}
                onClick={() => incrementLinkClick(jobID)}>
              </a>
            );
          }
        })}
      </div>
    );
  };
  
  function ensureProtocol(url) {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return 'https://' + url; // Default to secure protocol
    }
    return url;
  }
  
  
  const renderJobDeets = () => {
    const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

    const workArrangementDisplay = workArrangement ? capitalizeFirstLetter(workArrangement.replace('office', 'In-Office')) : '';
    const employmentTypeDisplay = employmentType ? capitalizeFirstLetter(employmentType) : '';

    return (
      <div className='role-details'>
        {workArrangement && <div><strong>Work Arrangement |&nbsp;</strong>{workArrangementDisplay}</div>}
        {employmentType && <div><strong>Type |&nbsp;</strong>{employmentTypeDisplay}</div>}
      </div>
    );
  };  
  
  const renderPayRange = (adjustCurrency=false) => {
    let minPayValue = minPay;
    let maxPayValue = maxPay;

    if (adjustCurrency) {
      minPayValue = job[`minPay_${userCurrency}`];
      maxPayValue = job[`maxPay_${userCurrency}`];
    }

    if (!minPayValue && !maxPayValue) return null;

    // Formatting the pay values with commas for readability
    const formattedMinPayValue = new Intl.NumberFormat().format(minPayValue);
    const formattedMaxPayValue = new Intl.NumberFormat().format(maxPayValue);

    let pay;
    if (minPayValue && maxPayValue) {
      pay = minPayValue === maxPayValue ? `${formattedMinPayValue}` : `${formattedMinPayValue} - ${formattedMaxPayValue}`;
    } else {
      pay = maxPayValue ? `Up to ${formattedMaxPayValue}` : `${formattedMinPayValue}`;
    }

    return `${pay} ${adjustCurrency ? userCurrency : currency} ${payInterval.charAt(0).toUpperCase() + payInterval.slice(1)}`;
  };

  const renderSkills = (skillType) => {
    const skills = skillType === 'primary' ? primarySkills : job.secondarySkills;
    if (skills.length === 0) return null; // Return null if no skills exist

    const title = skillType === 'primary' ? 'Required Skills' : 'Preferred Skills';

    return (
      <>
        <p><strong>{title}</strong></p>
        {skills.map((skill, index) => (
          <JobProperty key={index} category={skillType} value={skill} />
        ))}
      </>
    );
  };

  const renderBookmark = () => {
    return onBookmark !== null ? (
      job.star ? 
        <FontAwesomeIcon 
          className="gold-star-icon" 
          icon={faBookmarkSolid} 
          color="#2FC01A"
          onClick={() => onBookmark(job.jobID)}
        /> :
        <FontAwesomeIcon 
          className="star-icon-grey" 
          icon={faBookmarkRegular} 
          color="#A4A4A4"
          onClick={() => onBookmark(job.jobID)}
        />
    ) : null;
  };

  const renderTopRight = () => {
    return <span className="job-top-right-verbose">
      {/*views > 0 && <strong>{`${views}+ views`}</strong>*/}
      {!active && <span className="job-inactive-notice">This job is no longer active</span>}
      <div className="starbar-container-verbose" onClick={(e) => e.stopPropagation()}>
        {popular && <FontAwesomeIcon className="fire-icon-verbose" icon={faFire} />}
        {renderBookmark()}
      </div>
    </span>;
  };

  return (
    <div className="job-card-verbose">
        {renderTopRight()}

        <div className='job-card-section'>
            <div className="job-card-top">

                <div className="job-card-title">
                    <div className='job-company'>{company}</div>
                    <div className='job-title'>{jobTitle}</div>
                    <div className="job-location">
                    {location && country ? `${location}, ${country}` : location || country}
                    </div>
                    <div className="job-dates">
                        <div><strong>Posted | </strong>{new Date(added) < new Date(posted) ? added : posted}</div>
                        {new Date(added) < new Date(posted) && <div><strong>Reposted | </strong>{posted}</div>}
                    </div>
                </div>

                <div className="job-links">
                    <div><strong>Apply: </strong></div>
                    {renderLinks(link)}
                </div>

            </div>
        </div>

        <div className='job-card-section'>
          <div className="job-card-middle">

            <div className="left-section">

                <div className='role-details'>
                    {jobCategory && <div><strong>Job Role |&nbsp;</strong>{`${jobCategory.join("/ ")}`}</div>}
                    {marketSide && <div><strong>Market Side |&nbsp;</strong>{`${marketSide === 'busSide' ? 'Buy' : 'Sell'}`}</div>}
                </div>
                <div className='job-details'>
                  {renderJobDeets()}
                </div>
            </div>

            <div className="promoted-link">
              <div><strong>Promote this Job: </strong>{!promoted && !active && '(promoting the job will re-activate it)'}</div>
                {!promoted && (
                  <>
                    {renderLinks(`${window.location.origin}/checkout/${jobID}`)}
                  </>
                )}
                {promoted && <div>This job is currently promoted</div>}
            </div>

          </div>
        </div>

        {minPay || maxPay ? (
            <div className='job-card-section'>
                <div className='role-details'>
                    <div><strong>Pay |&nbsp;</strong>{renderPayRange()}</div>
                    {currency !== userCurrency && <div><strong>Equivalent |&nbsp;</strong>{renderPayRange({adjustCurrency: true})}</div>}
                </div>
            </div>
          ) : null}

        <div className='job-card-section'>
            <div className='skills'>
                {seniority && <div><strong>Seniority |&nbsp;</strong>{seniority.charAt(0).toUpperCase() + seniority.slice(1)}</div>}
                {experience && <div><strong>Experience |&nbsp;</strong>{`${experience}+ Years`}</div>}
                <div>{renderSkills('primary')}</div>
                <div>{renderSkills('secondary')}</div>
            </div>
        </div>

        <div className='job-card-section'>
            <div className='summary'>
                <p><strong>Job Summary</strong></p>
                {description_summary}
            </div>
        </div>

        {fullCompanySummary && (
          <div className='job-card-section'>
              <div className='summary'>
                  <p><strong>{company}</strong> {source === 'Recruiter' && ` - ${source}`}</p>
                  {fullCompanySummary} (AI Generated - Reach out to update).
              </div>
          </div>
        )}

    </div>
  );
};  

export default JobCard;
