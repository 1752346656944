import React, { useState, useEffect } from 'react';
import './analysisPage.css';
import ChartsGrid from './chartsGrid.jsx';
import MailchimpForm from '../utils/MailchimpForm';
import { Helmet } from 'react-helmet';
import {jobs_count} from './apiAnalysisPage.jsx';

const AnalysisPage = ({ currency, uniqueFilters }) => {

  const [filters, setFilters] = useState({
    country: "All Countries",
    role: "All Roles",
    seniority: "All Seniorities",
    employmentType: "All Employment Types",
    workArrangement: "All Work Arrangements",
    timespan: "Past 3 Months"
  });

  const timespans = [
    // "Incl Historic Listings",
    "Past 3 Months",
    "Active Listings Only"
  ];
  const roles = ["All Roles", "Quant Analyst", "Quant Risk", "Quant Developer", "Quant Researcher", "Quant Trader"];
  const seniorities = ["All Seniorities", "Junior", "Intermediate", "Senior"];

  const[jobCount, setJobCount] = useState(0);
  useEffect(() => {
    const fetchJobCount = async () => {
      const count = await jobs_count(filters);
      setJobCount(count);
    };
    fetchJobCount();
  }, [filters]);
  //scroll panel behaviour

  const [lastScrollY, setLastScrollY] = useState(window.scrollY);
  const [direction, setDirection] = useState('up');

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setDirection(currentScrollY > lastScrollY ? 'down' : 'up');
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);


  // visibility flags 
  const [visibilityFlags, setVisibilityFlags] = useState({
    countByCountry: true,
    countByEmploymentType: true,
    countByWorkArrangement: true,
    countByJobTitle: true,
    avgPayByCountry: true,
    avgPayByRole: true,
    skillCount: true,
    payBySkillPairing: false,
    countByCompany: true,
    avgSalaryBoostBySkill: true,
    countByIndustry: true,
    countByInstitutionType: true,
    countBySource:false,
  });

///////////// html /////////////
  return (

    <div>

    <Helmet>
      <title>Quant Job Market Analysis - Dive Deep into the Quantitative Job Market</title>
      <meta name="description" content="Explore comprehensive Quant job market analysis. Discover trends, salaries, and opportunities in the Quant ecosystem to enhance your career." />
      <meta property="og:title" content="Quantitative Job Market Analysis - Uncover Quant Career Insights" />
      <meta property="og:description" content="Get the latest insights and trends in the Quant job market. Analyze opportunities, salaries, and demand in the Quantitative analysis industry." />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={`${process.env.PUBLIC_URL}/preview_images/kdbMarketAnalysis.png`} />
      <meta property="og:url" content={window.location.href} />
    </Helmet>

      <div className="intro-container-analysis">
        <div className="intro-analysis">Quant Job Market Analysis</div>
        <div className="intro-container-analysis-2">
          <div className="intro-analysis-2">Real-time insights to power your job search</div>
        </div>
      </div>

      <div className="outer-container">

        <div className={`panel ${direction === 'down' ? 'hide' : ''}`}>

          <div className="dropdown-container">

            <select className="dropdown" value={filters.timespan} onChange={(e) => setFilters({...filters, timespan: e.target.value})}>
              {timespans.map((timeOption, index) => (
                <option key={index} value={timeOption}>
                  {timeOption}
                </option>
              ))}
            </select>

            <select className="dropdown" value={filters.country} onChange={(e) => setFilters({...filters, country: e.target.value})}>
              {uniqueFilters.countries.map((countryName, index) => (
                <option key={index} value={countryName}>
                  {countryName}
                </option>
              ))}
            </select>

            <select className="dropdown" value={filters.role} onChange={(e) => setFilters({...filters, role: e.target.value})}>
              {roles.map((role, index) => (
                <option key={index} value={role}>
                  {role}
                </option>
              ))}
            </select>

            <select className="dropdown" value={filters.seniority} onChange={(e) => setFilters({...filters, seniority: e.target.value})}>
              {seniorities.map((seniority, index) => (
                <option key={index} value={seniority}>
                  {seniority}
                </option>
              ))}
            </select>

            <select className="dropdown" value={filters.employmentType} onChange={(e) => setFilters({...filters, employmentType: e.target.value})}>
              {uniqueFilters.employmentTypes.map((employmentType, index) => (
                <option key={index} value={employmentType}>
                  {employmentType}
                </option>
              ))}
            </select>

            <select className="dropdown" value={filters.workArrangement} onChange={(e) => setFilters({...filters, workArrangement: e.target.value})}>
              {uniqueFilters.workArrangements.map((workArrangement, index) => (
                <option key={index} value={workArrangement}>
                  {workArrangement}
                </option>
              ))}
            </select>

          </div>

          <div className="jobCount">Viewing {jobCount} jobs</div>

        </div>

        <ChartsGrid currency={currency} visibilityFlags={visibilityFlags} filters={filters}/>

      </div>

      <div className="intro-container">
        <MailchimpForm />
      </div>

    </div>
  );

};

export default AnalysisPage;
