import React, { useState, useEffect } from 'react';
import './WeekdaySelector.css';

const WeekdaySelector = ({ selectedDays: initialSelectedDays, onUpdate }) => {
  const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

  // Initialize the state based on the initialSelectedDays prop
  const [selectedDays, setSelectedDays] = useState(
    days.map(day => !!initialSelectedDays[day])
  );

  // Effect to update state when the initialSelectedDays prop changes
  useEffect(() => {
    setSelectedDays(days.map(day => !!initialSelectedDays[day]));
  }, [initialSelectedDays]);

  const toggleDay = (index) => {
    const updatedDays = [...selectedDays];
    updatedDays[index] = !updatedDays[index];
    setSelectedDays(updatedDays);

    // Create a dictionary to pass to the onUpdate prop
    const updatedDaysDict = updatedDays.reduce((acc, selected, idx) => {
      acc[days[idx]] = selected;
      return acc;
    }, {});

    onUpdate(updatedDaysDict);
  };

  return (
    <div className="weekday-container">
      {days.map((day, index) => (
        <label key={day} className="day-label">
          <input
            type="checkbox"
            checked={selectedDays[index]}
            onChange={() => toggleDay(index)}
          />
          {day.toUpperCase()}
        </label>
      ))}
    </div>
  );
};

export default WeekdaySelector;
