import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { fetchJobDetail, checkBackendAndDBStatus } from '../api';
import JobCard from '../jobCard';
import JobBoostUserGuide from './JobBoostUserGuide.jsx'
import './PricingPage.css'; // Importing the CSS file for styles

const REACT_APP_STRIPE_PRICING_TABLE_ID = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;
const REACT_APP_STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

function PricingPage() {

  let { jobID } = useParams();
  const [job, setJob] = useState(null);
  const [backendStatus, setBackendStatus] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      try {
        const jobDetail = await fetchJobDetail(jobID);
        setJob(jobDetail);
        const status = await checkBackendAndDBStatus();
        setBackendStatus(status);
      } catch (error) {
        console.error("Failed to initialize page:", error);
        setBackendStatus(false);
      }
    };

    init();
  }, [jobID]);

  const handleJobClick = (jobID) => {
    navigate(`/job/${jobID}`); // Navigate to the job route
  };

  return (
    <div>
        <div className="intro-container-analysis">
        <div className="intro-analysis">Promote This Job</div>
        <div className="intro-container-analysis-2">
          <div className="intro-analysis-2">Boost Visibility - Boost Applicants</div>
        </div>
      </div>
      <div className="pricing-container">
        <JobBoostUserGuide/>
        <div>
        {job ? (
          <JobCard job={job} onCardClick={() => handleJobClick(job.jobID)}/>
        ) : (
          <div className="loadingContainer">Loading...</div>
        )}
        </div>
        {backendStatus ? (
          <div className="stripe-pricing">
            <stripe-pricing-table
              pricing-table-id={REACT_APP_STRIPE_PRICING_TABLE_ID}
              publishable-key={REACT_APP_STRIPE_PUBLISHABLE_KEY}
              client-reference-id={`${jobID}`}
            />
          </div>
        ) : (
          <div className="stripe-pricing">Unable to process payments at this time - please try again later.</div>
        )}
      </div>
    </div>
  );
}

export default PricingPage;
