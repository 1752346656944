import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';

const SortDropdown = ({ currentValue, onChange }) => {

  const options = [
    { label: "Relevant", value: "relevant" },
    { label: "Highest Paid", value: "money" },
    { label: "New", value: "added" },
    { label: "Most Popular", value: "popular" },
  ];

  const defaultOption = options.find(option => option.value === currentValue) || { label: "Relevant", value: "relevant" };
  const [selectedValue, setSelectedValue] = useState(defaultOption);

  const handleChange = value => {
    setSelectedValue(value);
    onChange(value.value); // Pass only the value to onChange
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '15px',
      fontSize: '0.8em',
      border: '2px solid #E7F2F8 !important',
      boxShadow: 'none !important',
      cursor: 'pointer !important',
      '&:hover': {
        cursor: 'pointer !important',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '0.8em',
      padding: 5,
      backgroundColor: state.isSelected ? '#E7F2F8' : '#ffffff', // Use a different color for selected, same for hover
      color: state.isSelected ? '#000000' : '#333333', // Optional: Change text color for better visibility
      '&:hover': {
        backgroundColor: state.isSelected ? '#E7F2F8' : '#ffffff', // Keep the background color on hover same as when it's not hovered
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: '5',
    }),
    menuList: (provided, state) => ({
      ...provided,
    }),
    valueContainer: (provided) => ({
      ...provided,
      justifyContent: 'center',
      padding: '0 8px',
    }),
    container: (provided) => ({
      ...provided,
      width: 'auto',
      minWidth: '7em'
    }),
  };  

  const ControlComponent = props => (
    <components.Control {...props}>
      <FontAwesomeIcon icon={faSort} style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', zIndex: 2 }} />
      {props.children}
    </components.Control>
  );

  return (
    <Select
      options={options}
      value={selectedValue}
      onChange={handleChange}
      className="react-select-container"
      classNamePrefix="react-select"
      isSearchable={false}  // Disabled searchable functionality
      placeholder="Sort by"
      components={{ Control: ControlComponent, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
      styles={customStyles}
    />
  );
};

export default SortDropdown;
