import React from 'react';
import ControlJobListingsCheckbox from './ControlJobListingsCheckbox';
import './EmployerJobsIntro.css';

const EmployerJobsIntro = ({ company, jobLimit }) => {
    const today = new Date();
    const maxActivationDate = new Date(today);
    maxActivationDate.setDate(today.getDate() + 60); // Adds 60 days to the current date

    return (
        <div className="job-portal-intro">

            <div className="about-section">
                <div className="content-wrapper">
                    <div className="content-text">
                        <p>You are now signed in and can manage <strong>{company}</strong> job listings directly!</p>
                        <p>Please note, a job can only be activated for a maximum of <strong>60 days</strong> from today's date.</p>
                        <p>Changes made may take up to <strong>10 minutes</strong> to be reflected in the application.</p>
                        <p><strong>{company}</strong> are able to add up to <strong>{jobLimit} active job posts</strong> below (reach out if you need more).</p>
                        <p><strong>Add jobs at the bottom of the page</strong></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployerJobsIntro;
