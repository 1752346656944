import React, { useState } from 'react';
import BubbleMap from './bubbleMap.jsx';
import FilterComponent from './FilterComponent.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import './MapAndSearch.css';

const MapAndSearch = ({ selectedFilters, setSelectedFilters, filteredJobsLocations, activeUsersCount }) => {
  // State to manage the visibility
  const [isVisible, setIsVisible] = useState(true);

  const handleFilterChange = (filters) => {
    setSelectedFilters(filters);
  };

  // Toggle visibility state
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="mas-container">
      <div className="filter-overlay">
        {isVisible && (
          <>
            <div className="intro-map">Uncover Global Opportunities in the World of Quant</div>
            <FilterComponent
              selectedFilters={selectedFilters}
              onFilterChange={handleFilterChange}
            />
          </>
        )}
      </div>
      <div className="filter-overlay-eye">
        <FontAwesomeIcon className={isVisible ? "eye-icon" : "eye-icon-invisible"} icon={isVisible ? faEyeSlash : faEye} onClick={toggleVisibility}/>
      </div>
      {isVisible && activeUsersCount > 1600 && (
        <div className="user-count-overlay">
          <FontAwesomeIcon className={"users-circle"} icon={faCircle}/>
          Weekly Active Users: {activeUsersCount}
        </div>
      )}
      <div className="map-background">
        <BubbleMap
          filteredJobsLocations={filteredJobsLocations}
          locationFilters={selectedFilters.location}
          setLocationFilters={(newLocationFilters) => setSelectedFilters({ ...selectedFilters, location: newLocationFilters })}
        />
      </div>
    </div>
  );
};

export default MapAndSearch;
