import React, { useState, useEffect } from 'react';
import FilterDropdown from './FilterDropdown';
import FilterSelectionDisplay from './FilterSelectionDisplay'; // Import the new component
import SearchBar from './SearchBar';
import { getDropDownList } from '../api_query_jobs';
import './FilterComponent.css';

const FilterComponent = ({ selectedFilters, onFilterChange }) => {

const [locationOptions, setLocationOptions] = useState([]);
const [seniorityOptions, setSeniorityOptions] = useState([]);
const [workArrangementOptions, setWorkArrangementOptions] = useState([]);
const [typeOptions, setTypeOptions] = useState([]);
const [companyOptions, setCompanyOptions] = useState([]);
const [skillsOptions, setSkillsOptions] = useState([]);
const [marketSideOptions, setMarketSideOptions] = useState([]);
const [jobCategoryOptions, setJobCategoryOptions] = useState([]);

useEffect(() => {
  const fetchDropDownList = async () => {
    setLocationOptions(await getDropDownList(['country','location']));
    setSeniorityOptions(['Junior', 'Intermediate', 'Senior']);
    setWorkArrangementOptions(await getDropDownList(['workArrangement']));
    setTypeOptions(await getDropDownList(['employmentType']));
    setCompanyOptions(await getDropDownList(['company']));
    setSkillsOptions(await getDropDownList(['primarySkills', 'secondarySkills']))
    setMarketSideOptions(['BuySide', 'SellSide'])
    setJobCategoryOptions(['Quant Analyst', 'Quant Risk', 'Quant Researcher', 'Quant Developer', 'Quant Trader'])
  };
  fetchDropDownList();
}, []);

const resetFilters = () => {
    const resetState = {
      search: selectedFilters.search, // Keep the search filter as it is
      location: [],
      seniority: [],
      workArrangement: [],
      type: [],
      company: [],
      skills: [],
      jobCategory: [],
      marketSide: [],
      sort: selectedFilters.sort
      // Do not reset 'sort' here if you're managing it separately or want it to persist
    };
    onFilterChange({ ...resetState});
  };

  const handleSearchChange = (searchString) => {
    const updatedFilters = { ...selectedFilters, search: searchString };
    onFilterChange(updatedFilters);
  };

  // Handler to append a new selection
  const handleSelectionChange = (filterType, selectedOption) => {
    if (selectedOption && !selectedFilters[filterType].find(option => option.value === selectedOption.value)) {
      const newFilters = {
        ...selectedFilters,
        [filterType]: [...selectedFilters[filterType], selectedOption.value],
      };
      onFilterChange(newFilters); // Notify the parent component of the filter change
    }
  };

  const handleRemoveFilter = (filterType, selectedOption) => {
    console.log(filterType);
    console.log(selectedOption);
    console.log(selectedFilters);

    // Assuming selectedOption is a string and not an object with a value property
    const filteredOptions = selectedFilters[filterType].filter(option => option !== selectedOption);
    const newFilters = {
      ...selectedFilters,
      [filterType]: filteredOptions,
    };

    console.log(newFilters);
    onFilterChange(newFilters);
  };

  return (
    <div className='filter-component'>
      <SearchBar handleSearchChange={handleSearchChange} initialValue={selectedFilters.search}/>
      <FilterSelectionDisplay selectedFilters={selectedFilters} onClearFilters={resetFilters} onRemoveFilter={handleRemoveFilter} includeKdb={true}/>
      <div className="filter-container">
        <FilterDropdown label="Location" options={locationOptions} onChange={(selectedOption) => handleSelectionChange('location', selectedOption)} />
        <FilterDropdown label="Seniority" options={seniorityOptions} onChange={(selectedOption) => handleSelectionChange('seniority', selectedOption)} />
        <FilterDropdown label="Work From" options={workArrangementOptions} onChange={(selectedOption) => handleSelectionChange('workArrangement', selectedOption)} />
        <FilterDropdown label="Type" options={typeOptions} onChange={(selectedOption) => handleSelectionChange('type', selectedOption)} />
        <FilterDropdown label="Company" options={companyOptions} onChange={(selectedOption) => handleSelectionChange('company', selectedOption)} />
        <FilterDropdown label="Skills" options={skillsOptions} onChange={(selectedOption) => handleSelectionChange('skills', selectedOption)} />
        <FilterDropdown label="Role" options={jobCategoryOptions} onChange={(selectedOption) => handleSelectionChange('jobCategory', selectedOption)} />
        <FilterDropdown label="Side" options={marketSideOptions} onChange={(selectedOption) => handleSelectionChange('marketSide', selectedOption)} />
      </div>
    </div>
  );
};

export default FilterComponent;