import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import './ConfirmationPage.css'; // Importing the CSS file for styles

function ConfirmationPage() {


  return (
    <div className='confirmationPage'>
        <div className="intro-container-analysis">
            <div className="intro-analysis">Thanks for Promoting This Job!</div>
            <div className="intro-container-analysis-2">
            <div className="intro-analysis-2">It may take a few minutes to take effect</div>
            </div>
        </div>

        <div className='circleCheckIcon'>
            <FontAwesomeIcon className="arrow-icon" icon={faCircleCheck} />
        </div>
    </div>
  );
}

export default ConfirmationPage;